import React from 'react';
import GooglePlayJsonData from "../assetlinks.json";

const AndroidAppDeepLinkPolicy = () => {
    const data = JSON.stringify(GooglePlayJsonData, null, 2);
  return (
    <div>
        <pre>
            {data}
        </pre>
    </div>
  );
};

export default AndroidAppDeepLinkPolicy;
