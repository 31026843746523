import React from 'react';
import styles from '../styles/components/PujaProgressLine.module.css';
import { useTranslation } from "react-i18next";

const PujaProgressLine = ({ activeStep }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.parent_container}>
      <div className={styles.progressBar}>
      <div className={`${styles.step} ${activeStep >= 1 ? styles.active : ''}`}>
        <div className={styles.circle}>1</div>
        <p>{t("Login")}</p>
      </div>
      <div className={styles.line}></div>
      <div className={`${styles.step} ${activeStep >= 2 ? styles.active : ''}`}>
        <div className={styles.circle}>2</div>
        <p>{t("Confirmation")}</p>
      </div>
      <div className={styles.line}></div>
      <div className={`${styles.step} ${activeStep >= 3 ? styles.active : ''}`}>
        <div className={styles.circle}>3</div>
        <p>{t("Payment")}</p>
      </div>
      <div className={styles.line}></div>
      <div className={`${styles.step} ${activeStep >= 4 ? styles.active : ''}`}>
        <div className={styles.circle}>4</div>
        <p>{t("Information")}</p>
      </div>
    </div>
    </div>
  );
};

export default PujaProgressLine;