import React, { useEffect, useState } from "react";
import heartIcon from "../assets/heart.png";
import heartRedIcon from "../assets/heart_red.png";
import trendingIcon from "../assets/trending.png";
import starIcon from "../assets/star.png";
import bookedIcon from "../assets/booked.png";
import styles from "../styles/screens/Ayodhya/Ayodhya.module.css";
import { useNavigate } from "react-router-dom";
import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function PujaCard({
  id,
  name,
  price,
  group,
  path,
  discounted_price,
  benefits,
  rating,
  trending,
  booked_by,
  imageUrl,
}) {
  const navigate = useNavigate();
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const phone = JSON.parse(localStorage.getItem("phoneNumber"))
    ? JSON.parse(localStorage.getItem("phoneNumber"))
    : "";
  const [savedPuja, setSavedPuja] = useState({});

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const fetchSavedPuja = async () => {
      if (phone === "") {
        return;
      }
      const savedRef = doc(db, "saved_puja", phone, "1", "saved_pujas");
      const savedDoc = await getDoc(savedRef);
      if (savedDoc.exists()) {
        setSavedPuja(savedDoc.data());
      }
    };
    fetchSavedPuja();
  }, [phone]);

  const savePuja = async (id) => {
    if (phone === "") {
      navigate("/login");
      return;
    }
    try {
      const savedRef = doc(db, "saved_puja", phone, "1", "saved_pujas");
      const savedDoc = await getDoc(savedRef);

      if (savedDoc.exists()) {
        const savedData = savedDoc.data();
        if (savedData[id]) {
          const updatedPujaIds = { ...savedData, [id]: false };
          await updateDoc(savedRef, updatedPujaIds);
          setSavedPuja(updatedPujaIds);
        } else {
          const updatedPujaIds = { ...savedData, [id]: true };
          await updateDoc(savedRef, updatedPujaIds);
          setSavedPuja(updatedPujaIds);
        }
      } else {
        await setDoc(savedRef, { [id]: true });
        setSavedPuja([id]);
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.puja_icons}>
        <div className={styles.trending}>
          {trending && (
            <div>
              <img
                src={trendingIcon}
                alt={name}
                className={styles.action_icon}
              />
              <span>{t("Trending")}</span>
            </div>
          )}
        </div>
        <div className={styles.heart}>
          <img
            src={savedPuja[id] ? heartRedIcon : heartIcon}
            alt={name}
            className={styles.action_icon}
            onClick={() => {
              savePuja(id);
            }}
          />
        </div>
      </div>
      <img
        src={imageUrl}
        alt={name}
        className={styles.puja_image}
        onClick={() => {
          navigate("/puja/" + group + "/" + id, {
            state: { id: id, path: path },
          });
        }}
      />
      <div className={styles.list_details}>
        <div className={styles.puja_details}>
          <div className={styles.puja_name}>
            <span className={styles.puja_title}>{name}</span>
            <div></div>
            <span className={styles.puja_subtitle}>
              {t("Benefits") + " : " + benefits}
            </span>
          </div>
          <div className={styles.rating}>
            <img src={starIcon} alt={name} className={styles.rating_icon} />
            {rating}
          </div>
        </div>
        <b className={styles.booked_subtitle}>
          <img src={bookedIcon} alt={name} className={styles.booked_icon} />
          {language === "en" ? booked_by.en : booked_by.hi}
        </b>
        <div className={styles.border}></div>
        <button
          className={styles.book_button}
          onClick={() => {
            navigate("/puja/" + group + "/" + id, {
              state: { id: id, path: path },
            });
          }}
        >
          {t("Price")}:{" "}
          {discounted_price !== -1 ? <del>{"₹" + price}</del> : ""}
          {" ₹"}
          <strong>{discounted_price !== -1 ? discounted_price : price}</strong>
        </button>
      </div>
    </div>
  );
}
export default PujaCard;
