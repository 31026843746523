import React, {useEffect} from "react";
import NavBar from "../components/Navbar";
import NotFoundImage from "../assets/404.png";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { usePostHog } from 'posthog-js/react'

const Page404 = () => {
  const { t } = useTranslation();
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);

  return (
    <div>
      <NavBar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff1ea",
          height: "100vh",
        }}
      >
        <img src={NotFoundImage} alt={t("404 Error")} />
        <h1>{t("Page Not Found")}</h1>
        <a href="/" style={{ textDecoration: "none", color: "#ff6200" }}>
          {t("Go back to homepage")}
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Page404;
