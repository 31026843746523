import React, {useEffect} from "react";
import NavBar from "../../components/Navbar";
import styles from "../../styles/screens/Privacypolicy.module.css";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";
import { usePostHog } from 'posthog-js/react'

const ShipmentPolicy = () => {
  const { t } = useTranslation();
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);

  return (
    <div>
      <NavBar />
      <div className={styles.privacy_policy_text}>
        <h2>{t("termsAndConditions.dharmikBharatShipmentPolicyTitle")}</h2>
        <p>{t("termsAndConditions.dharmikBharatShipmentPolicyContent")}</p>

        <h4>{t("termsAndConditions.serviceAreasTitle")}</h4>
        <p>{t("termsAndConditions.serviceAreasContent")}</p>

        <h4>{t("termsAndConditions.poojaServicesAndPrasadPackagingTitle")}</h4>
        <p>{t("termsAndConditions.poojaServicesAndPrasadPackagingContent")}</p>

        <h4>{t("termsAndConditions.shippingTimelinesTitle")}</h4>
        <p>{t("termsAndConditions.shippingTimelinesContent")}</p>

        <h4>{t("termsAndConditions.trackingOrdersTitle")}</h4>
        <p>{t("termsAndConditions.trackingOrdersContent")}</p>

        <h4>{t("termsAndConditions.undeliverablePackagesTitle")}</h4>
        <p>{t("termsAndConditions.undeliverablePackagesContent")}</p>

        <h4>{t("termsAndConditions.damagedOrLostPackagesTitle")}</h4>
        <p>{t("termsAndConditions.damagedOrLostPackagesContent")}</p>

        <h4>{t("termsAndConditions.customsAndRegulatoryComplianceTitle")}</h4>
        <p>{t("termsAndConditions.customsAndRegulatoryComplianceContent")}</p>

        <h4>{t("termsAndConditions.cancellationAndRefundPolicyTitle")}</h4>
        <p>{t("termsAndConditions.cancellationAndRefundPolicyContent1")}</p>
        <p>{t("termsAndConditions.cancellationAndRefundPolicyContent2")}</p>

        <p>{t("termsAndConditions.contactInformation")}</p>
        <p>{t("termsAndConditions.thankYouNote")}</p>
      </div>
      <Footer />
    </div>
  );
};

export default ShipmentPolicy;
