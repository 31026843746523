import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../components/Navbar";
import styles from "../styles/screens/BookingResults.module.css";
import Footer from "../components/Footer";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import payment_success_image from "../assets/payment_success.png";
import payment_failure_image from "../assets/payment_failure.png";
import payment_processing_image from "../assets/payment_processing.png";
import { db } from "../utils/firestoredb";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import RazorpayOnlinePay from "../components/Payment/RazorpayOnlinePay";
import PujaProgressLine from "../components/PujaProgressLine";
import Metadata from "../components/metadata/MetaData";
import { usePostHog } from 'posthog-js/react'
import {Oval} from 'react-loader-spinner';


const BookingResult = () => {
  const { pujaId, offeringId, orderId } = useParams();
  const [status, setStatus] = useState("");
  const [language, setLanguage] = useState("en");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const posthog = usePostHog();

  const phone = JSON.parse(localStorage.getItem("phoneNumber"));
  const [pujaDetails, setPujaDetails] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const isLoggedIn = JSON.parse(localStorage.getItem("phoneNumber")) ? true : false;

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

useEffect(() => {
    const fetchPujaDetails = async () => {
      let pujaDocRef = doc(db, "ayodhyapuja", pujaId);
      const pujaDocSnap = await getDoc(pujaDocRef);
  
      if (pujaDocSnap.exists()) {
        setPujaDetails(pujaDocSnap.data());
      } else {
        console.log("Error Fetching Puja Details for Puja: ", pujaId);
      }
    };
  
    fetchPujaDetails();
  }, [pujaId]);
  
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const orderRef = doc(db, "orders", orderId);
        const orderSnapshot = await getDoc(orderRef);
        if (orderSnapshot.exists()) {
          setOrderData(orderSnapshot.data());
        }
      } catch (error) {
        console.error("Error fetching Order Details: ", error);
      }
    };
  
    fetchOrderDetails();
  }, [orderId]);
  
  useEffect(() => {
    fetch("https://checkorder-ptmfa2eama-uc.a.run.app", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderId: orderId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setStatus(data.status);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [orderId]);

  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);
  

  if(!isLoggedIn) navigate("/login/" + pujaId + "/" + offeringId);
  if (!pujaDetails && !orderData) {
    return(
      <Oval 
        color="#ff8e4d"
        height="20%"
        width="20%"
        strokeWidth={4}
        ariaLabel="Loading"
        wrapperStyle={{position: "fixed", top: "45%", left: "45%"}}
        wrapperClass="loader"
      />
  );
  } else {
    //console.log("PUJA DETAILS: ", pujaDetails);
    //console.log("ORDER DETAILS: ", orderData);
  }

  const renderButton = () => {
    if(status && orderData) {
        if (orderData[orderId].status === "ORDER_IN_TRANSIT") {
            return null;
          } else if (status === "paid" || orderData[orderId].rzp_status === "paid" || orderData[orderId].paymentMethod === "cod") {
            return (
              <button className={styles.button} onClick={() => goToHomepage()}>
                {t("Go to Homepage")}
              </button>
            );
          } else if (status === "failed" || orderData[orderId].rzp_status === "dismiss" || orderData[orderId].rzp_status === "failed" || (status === "attempted" && orderData[orderId].paymentMethod === "online")) {
            return (
              <button className={styles.button} onClick={() => retryPayment()}>
                {t("Retry")}
              </button>
            );
          } else if (orderData[orderId].paymentMethod === "online" && status === "created") {
            return (
              <button className={styles.button} onClick={() => navigate("/")}>
                {t("Go to Home")}
              </button>
            );
          }
    }
  };

  const goToHomepage = () => {
    navigate("/");
  };

  const paymentStatus = () => {
      if (status && orderData) {
        if(orderData[orderId].paymentMethod === "cod") return "Order Confirmned";
        if(orderData[orderId].paymentMethod === "online"){
          if (status === "paid" || orderData[orderId].rzp_status === "paid") return "Payment Successful";
          else if (status === "failed" || status === "attempted" || orderData[orderId].rzp_status === "dismiss") return "Payment Failed";
          else if (status === "created") return "Payment Procressing";
       }
      }
  }

  const paymentImage = () => {
    if (status && orderData) {
      if(orderData[orderId].paymentMethod === "cod") return payment_success_image;
      if(orderData[orderId].paymentMethod === "online"){
        if (status === "paid") return payment_success_image;
        else if (status === "failed" || status === "attempted") return payment_failure_image;
        else if (status === "created") return payment_processing_image;
    }
    }
  }

  const paymentDescription = () => {
    if (status && orderData) {
      if(orderData[orderId].paymentMethod === "cod") return "You will receive your Order in 5-7 days.";
      if(orderData[orderId].paymentMethod === "online"){
        if (status === "paid") return "You will receive your Order in 5-7 days.";
        else if (status === "failed" || status === "attempted") return "If your payment was deducted but the transaction couldn't be completed, don't worry. Your funds will be automatically refunded to your account within 7-8 working days.";
        else if (status === "created") return "Your payment is currently being processed, you will receive a confirmation shortly.";
      }
    }
  }

  const retryPayment = async => {
    RazorpayOnlinePay(
      pujaId,
      offeringId,
      orderId,
      orderData[orderId].price,
      phone,
      orderData[orderId].pujadescription,
      phone
    );
  };

  if (status && orderData)
  return (
    <div>
      <Metadata
        description="Dharmik online puja and prasad in Ayodhya"
        keywords="dharmik, dharmikapp, ayodhya, puja, prasad, online puja, online prasad, hindu puja online"
        ogTitle="Dharmik App online puja and prasad in Ayodhya"
        ogDescription="Online puja and prasad in Ayodhya via Dharmik App"
        ogImage="https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png"
        ogUrl="https://dharmikapp.in"
        canonicalUrl="https://dharmikapp.in"
        twitter="@dharmikapp"
        youtube="@dharmik_hindi"
        instagram="@dharmik_app"
        author="Dharmik App"
        publisher="Dharmik"
        datePublished="2024-09-06"
       />
      <NavBar />
      <PujaProgressLine activeStep={4}/>
      <div className={styles.result}>
        <div className={styles.cardContainer}>
          {/* <h3 className={styles.title}>{t("Status : ") + t(status)}</h3>
          <h3 className={styles.pujaTitle}>{t("Order ID : ") + orderId}</h3>
          {renderButton()} */}
          <div className={styles.card}>
            <section className={styles.section}>
              <img
                src={pujaDetails.banner_image[language]}
                alt="Puja"
                className={styles.pujaImage}
              />
              <div className={styles.pujaTitle}>
                      <span>{language === "en" ? pujaDetails.offerings[offeringId-1].title?.en : pujaDetails.offerings[offeringId-1].title?.hi}</span>
              </div>
              <div className={styles.puja_description}>
                      <span>{language === "en" ? pujaDetails.offerings[offeringId-1].description?.en : pujaDetails.offerings[offeringId-1].description?.hi}</span>
              </div>
              <div className={styles.shulk_container}>
                    <div className={styles.pay_shulk}>
                        <span>{t("Puja Shulk")}</span>
                        <span>{orderData[orderId].price}</span>
                    </div>
                </div>
            </section>
            <aside className={styles.aside}>
                <div className={styles.paymentSuccess}>
                    <img
                        src={paymentImage()}
                        alt="Payment status"
                        className={styles.successImage}
                    />
                    <h3 className={styles.successText}>{paymentStatus()}</h3>
                    <p className={styles.payment_description}>{paymentDescription()}</p>
                    {renderButton()}
                </div>
            </aside>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BookingResult;
