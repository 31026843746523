import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import HomeScreen from "./screens/Homescreen";
import PrivacyPolicy from "./screens/Policy/PrivacyPolicy";
import Page404 from "./screens/Page404";
import PujaPage from "./screens/Booking/PujaPage";
import AboutUs from "./screens/AboutUs";
import TermsAndConditions from "./screens/Policy/Terms&Conditions";
import ContactUs from "./screens/ContactUs";
import Waitlist from "./screens/Waitlist";
import ProfilePage from "./screens/Profile/ProfilePage";
import "./i18n";
import Payment1 from "./screens/Booking/Payment1";
import LivepujaPayment from "./screens/Booking/LivepujaPayment";
import Payment2 from "./screens/Booking/Payment2";
import Payment3 from "./screens/Booking/Payment3";
import ShipmentPolicy from "./screens/Policy/ShipmentPolicy";
import LoginPay from "./screens/Booking/LoginPay";
import Result from "./screens/Result";
import AdminPanel from "./screens/AdminPanel";
import AppAssetLink from "./screens/AppAssetLink";
import RefundPolicy from "./screens/Policy/RefundPolicy";
import Referral from "./screens/Referral/Referral";
import Login from "./screens/Profile/Login";
import MerchantLogin from "./screens/Profile/MerchantLogin";
import ReferralPolicy from "./screens/Policy/ReferralPolicy";
import AdminLogin from "./screens/Profile/AdminLogin";
import LivePuja from "./screens/Booking/LivePuja";

//posthog
import { PostHogProvider} from 'posthog-js/react'
import PujaDetails from "./screens/Booking/PujaDetails";
import PujaInfo from "./screens/PujaInfo";
import BookingResult from "./screens/BookingResult";
import PujaBookingForm from "./screens/Booking/PujaBookingForm";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  autocapture: true, // Enabling automatic events capture
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/puja/:group/:id",
    element: <PujaPage />,
  },
  {
    path: "/live/:id",
    element: <LivePuja />,
  },
  {
    path: "/puja/:pujaId",
    element: <PujaDetails />,
  },
  {
    path: "/pujainfo/:pujaId/:offeringId",
    element : <PujaInfo />,
  },
  {
    path: "/booking/results/:pujaId/:offeringId/:orderId",
    element: <BookingResult />,
  },
  {
    path: "/bookingform/:pujaId/:offeringId/:orderId",
    element: <PujaBookingForm />,
  },
  {
    path: "/login/:group/:id",
    element: <LoginPay />,
  },
  {
    path: "/payment/result/:group/:id/:orderId",
    element: <Result />,
  },
  {
    path: "/puja/:group/:id/payment1",
    element: <Payment1 />,
  },
  {
    path: "/livepuja/:id/payment",
    element: <LivepujaPayment />,
  },
  {
    path: "/puja/:group/:id/payment2",
    element: <Payment2 />,
  },
  {
    path: "/puja/:group/:id/payment3",
    element: <Payment3 />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/referral",
    element: <Referral />,
  },
  {
    path: "terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/shipment-policy",
    element: <ShipmentPolicy />,
  },
  {
    path: "/referral-policy",
    element: <ReferralPolicy />,
  },
  {
    path: "/cancellation-policy",
    element: <RefundPolicy />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup/:parentReferralId",
    element: <Login />,
  },
  {
    path: "/orderadminpanel",
    element: <AdminPanel />,
  },
  {
    path: "/orderadminpanel/login",
    element: <AdminLogin />,
  },
  {
    path: "/merchant/login",
    element: <MerchantLogin />,
  },
  {
    path: "/merchant/signup/:parentReferralId",
    element: <MerchantLogin />,
  },
  {
    path: "/join-waitlist/:message",
    element: <Waitlist />,
  },
  {
    path: "/orderadminpanel",
    element: <AdminPanel />,
  },
  {
    path: "/.well-known/assetlinks.json",
    element: <AppAssetLink />,
  },
  {
    path: "/install",
  },
  {
    path: "*",
    element: <Page404 />,
  },
  {
    path: "/sitemap.xml",
    element: null,
    loader: () => {
      window.location.href = '/sitemap.xml';
      return null;
    },
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
      >
    <RouterProvider router={router} />
    </PostHogProvider>
    </React.StrictMode>
);
