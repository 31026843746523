import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../styles/screens/Referral.module.css";
import cartImage from "../../assets/referral/cart.png";
import moneyImage from "../../assets/referral/money.png";
import shareImage from "../../assets/referral/share.png";
import noteImage from "../../assets/referral/note.png";

const MiddlePart = ({ userType }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.middle_part}>
      <h1>{t("How Does It Work?")}</h1>
      <div className={styles.middle_options}>
        <div>
          <img src={shareImage} alt={t("share")} className={styles.icon} />
          {userType === "general" ? (
            <>
              <h3>1. {t("Share with friends")}</h3>
              <p>{t("Share the code/link given below.")}</p>
            </>
          ) : (
            <>
              <h3>1. {t("Ask customer to down the app.")}</h3>
              <p>{t("They can scan the QR assigned to you.")}</p>
            </>
          )}
        </div>
        <div>
          {userType === "general" ? (
            <>
              <img src={cartImage} alt={t("cart")} className={styles.icon} />
              <h3>2. {t("They book a puja")}</h3>
              <p>{t("You earn 10% on ever puja booked.")}</p>{" "}
            </>
          ) : (
            <>
              <img src={noteImage} alt={t("note")} className={styles.icon} />
              <h3>2. {t("They enter your referral code")}</h3>
              <p>{t("Ask them to enter your unique referral code")}</p>{" "}
            </>
          )}
        </div>
        <div>
          <img src={moneyImage} alt={t("money")} className={styles.icon} />
          <h3>3. {t("Earn on Downloads and Pujas")}</h3>
          <p>
            {t("On every download and puja booked by them, you earn money.")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MiddlePart;
