import React, { useState, useEffect } from "react";
import NavBar from "../../components/Navbar";
import styles from "../../styles/screens/Ayodhya/Pujapage.module.css";
import templeIcon from "../../assets/ic_temple.png";
import Footer from "../../components/Footer";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, getDoc, doc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import firebaseConfig from "../../firebase";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PujaReview from "../../components/PujaReview";
import Step1Icon  from "../../assets/step1.svg";
import Step2Icon  from "../../assets/step2.svg";
import Step3Icon  from "../../assets/step3.svg";
import Step4Icon  from "../../assets/step4.svg";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function PujaPage() {
  const { group, id } = useParams();
  const navigate = useNavigate();
  const [pujakereviews, setpujakereviews] = useState([]);
  const [pujaDetails, setPujaDetails] = useState(null);
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState();
  const [devoteesNumber, setDevoteesNumber] = useState("100");
  const [imageList, setImageList] = useState([]);
  const [highlight, setHighlight] = useState(true);

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    if (highlight) {
      const timer = setTimeout(() => {
        setHighlight(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [highlight]);

  useEffect(() => {
    const fetchPujaDetails = async () => {
      const pujaDocRef = collection(db, "poojaplaces", group, "offerings");
      const pujaQuerySnapshot = await getDocs(pujaDocRef);

      const reviewGroupData = doc(db, "reviews", group);
      const reviewSpecificDocRef = doc(reviewGroupData, "reviews", id);
      const reviewGroupDocSnapshot = await getDoc(reviewGroupData);
      const reviewSpecificDocSnapshot = await getDoc(reviewSpecificDocRef);

      try {
        const reviewsData = [];
        if(reviewGroupDocSnapshot.exists() && reviewSpecificDocSnapshot.exists()){
          const docData = reviewSpecificDocSnapshot.data();
          reviewsData.push(...docData.review);
        }
        setpujakereviews(reviewsData);
      } catch (error) {
        console.log("Error getting documents:", error);
      }

      try {
        pujaQuerySnapshot.forEach((pujaDocument) => {
          const offeringData = pujaDocument.data();
          if (offeringData.offeringId === id) {
            setPujaDetails(offeringData);
            const devotestring = offeringData.booked_by.en;
            console.log("Booked by string: ", devotestring);
            const number = parseInt((devotestring.match(/\d+/) || [100])[0], 10);
            setDevoteesNumber(number);
            if (Array.isArray(offeringData.image)) {
              setImageList(offeringData.image);
              } else if (typeof offeringData.image === 'string') {
              setImageList([offeringData.image]);
              }
            }
        });
      } catch (error) {
        console.error("Error getting documents:", error);
      }
    };

    fetchPujaDetails();
  }, [group, id]);

  if (!pujaDetails) {
    return <div>Loading...</div>;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setHighlight(true); 
  };

  const isLoggedIn = JSON.parse(localStorage.getItem("phoneNumber"))
    ? true
    : false;

  const analytics = getAnalytics(app);
  logEvent(analytics, "Visited: " + pujaDetails.name.en + " Page");

  return (
    <div className={styles.ayodhya}>
      <NavBar />
      <a href={"/puja/" + group} className={styles.back}>
      </a>
      <aside className={styles.listing}>
        `<div className={styles.carouselContainer}>
            <Carousel showThumbs={false} infiniteLoop={false} autoPlay={true}>
                {imageList.map((image, index) => (
                    <div key={index}>
                        <img src={image} alt={`Puja ${index + 1}`} className={styles.pujaImage} />
                    </div>
                ))}
            </Carousel>
        </div>`
        <div className={styles.pujaParentDiv}>
          <header className={styles.header}>
            <div className={styles.iconTextWrapper}>
              <img src={templeIcon} alt="Temple Icon" className={styles.icon} />
              <h1 className={styles.title}>
                {language === "en"
                  ? pujaDetails.name?.en
                  : pujaDetails.name?.hi}
              </h1>
            </div>
            {pujaDetails.description?.en && (
              <p className={styles.subtitle}>
                {language === "en"
                  ? pujaDetails.description?.en
                  : pujaDetails.description?.hi}
              </p>
            )}
            <div className={styles.container}>
              <img src="https://d3vqmpzcil03dt.cloudfront.net/review_images-website/review_image_1.jpg" alt="Review 1" className={`${styles.image0} ${styles.image1}`} />
              <img
  src={`https://d3vqmpzcil03dt.cloudfront.net/review_images-website/review_image_${generateRandomNumbers()}.jpg`}
  alt="Review 2"
  className={`${styles.image0} ${styles.image2}`}
/>
              <img src="https://d3vqmpzcil03dt.cloudfront.net/review_images-website/review_image_20.jpg" alt="Review 3" className={`${styles.image0} ${styles.image3}`} />
              <p className={styles.text1}>
    <strong>Till now <span className={styles.colorText}>{devoteesNumber}+ Devotees</span> have booked pooja</strong>
  </p>
</div>
<strong className={styles.reviewsTitle}>Reviews</strong>
<div className={styles.scrollContainer}>
  {pujakereviews.map((review, i) => (
    <div key={review.id} style={{ display: 'inline-block', width: '60%', margin: '0 0px' }}>
      <PujaReview {...review} />
    </div>
  ))}
</div>
          </header>
          <button
            className={styles.bookButton}
            onClick={() => {
              if (isLoggedIn) {
                if (pujaDetails.payment_type === 1) {
                  navigate("/puja/" + group + "/" + id + "/payment1", {
                    state: { id: id },
                  });
                } else if (pujaDetails.payment_type === 2) {
                  navigate("/puja/" + group + "/" + id + "/payment2", {
                    state: { id: id },
                  });
                } else if (pujaDetails.payment_type === 3) {
                  navigate("/puja/" + group + "/" + id + "/payment3", {
                    state: { id: id },
                  });
                }
              } else {
                navigate("/login/" + group + "/" + id, {
                  state: { id: id },
                });
              }
            }}
          >
            {t("Book Pooja at Rs. ") + " "}
            {pujaDetails.price.discounted_price > 0 ? (
              <del>{" ₹" + pujaDetails.price.en}</del>
            ) : (
              ""
            )}
            <strong>
              {pujaDetails.price.discounted_price
                ? " ₹" + pujaDetails.price.discounted_price
                : pujaDetails.price.en}
            </strong>
          </button>
        </div>
      </aside>
      <main className={styles.mainContent}>
      <div className={styles.tabs}>
        <div className={styles.tabContainer}>
          <div className={`${activeTab === "benefits" ? styles.activeTab : styles.tab} ${styles.tabWidth}`} onClick={() => handleTabChange("benefits")}>
            {t("Benefits")}
          </div>
        </div>
        <div className={styles.tabContainer}>
          <div className={`${activeTab === "itemsNeeded" ? styles.activeTab : styles.tab} ${styles.tabWidth}`} onClick={() => handleTabChange("itemsNeeded")}>
            {t("Items Needed")}
          </div>
        </div>
        <div className={styles.tabContainer}>
          <div className={`${activeTab === "whatYouWillGet" ? styles.activeTab : styles.tab} ${styles.tabWidth}`} onClick={() => handleTabChange("whatYouWillGet")}>
            {t("What you will get")}
          </div>
        </div>
        <div className={styles.tabContainer}>
          <div className={`${activeTab === "poojaProcess" ? styles.activeTab : styles.tab} ${styles.tabWidth}`} onClick={() => handleTabChange("poojaProcess")}>
            {t("Pooja Process")}
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <section className={styles.features}>
        <div className={`${activeTab === "benefits" ? `${styles.highlightBox} ${!highlight ? styles.noBorder : ''}` : ''}`}>
          <h2>{t("Benefits")}</h2>
          <ul>
            {pujaDetails.benefits?.map((benefit, index) => (
              <li key={index}>
                {language === "en" ? benefit?.en : benefit?.hi}
              </li>
            ))}
          </ul>
        </div>
        <div className={`${activeTab === "itemsNeeded" ? `${styles.highlightBox} ${!highlight ? styles.noBorder : ''}` : ''}`}>
          <h2>{t("Items Needed")}</h2>
          <ul>
            {pujaDetails.samagri?.map((samagri, index) => (
              <li key={index}>
                {language === "en" ? samagri?.en : samagri?.hi}
              </li>
            ))}
          </ul>
        </div>
        <div className={`${activeTab === "whatYouWillGet" ? `${styles.highlightBox} ${!highlight ? styles.noBorder : ''}` : ''}`}>
          <h2>{t("What you will get")}</h2>
          <ul>
            {pujaDetails.outcomes?.map((outcome, index) => (
              <li key={index}>
                {language === "en" ? outcome?.en : outcome?.hi}
              </li>
            ))}
          </ul>
        </div>
        <div className={`${activeTab === "poojaProcess" ? `${styles.highlightBox} ${!highlight ? styles.noBorder : ''}` : ''}`}>
          <h2>{t("Pooja Process")}</h2>
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '0 0px' }}>
            {Array.from({ length: 4 }, (_, index) => (
              <div key={index} style={{ display: 'inline-block', width: 240, margin: '0 0px' }}>
                <div style={{ width: 220, height: 80, marginRight: 20, borderRadius: 10, backgroundColor: "#ED884266" }}>
                  <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <div style={{ padding: "5px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: 10 }}>
                          <div style={{ width: 60, height: 60, borderRadius: "50%", overflow: "hidden" }}>
                            <img
                              src={index === 0 ? Step1Icon : index === 1 ? Step2Icon : index === 2 ? Step3Icon : Step4Icon}
                              style={{ width: "100%", height: "100%", objectFit: "cover" }}
                              alt="Puja Process"
                            />
                          </div>
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <h3 style={{ fontWeight: "bold", margin: "0" }}>Step {index + 1}</h3>
                          <p style={{ margin: "0", whiteSpace: 'pre-wrap', fontSize: '12px' }}>
                            {index === 0
                              ? "Enter your Name and Gotra"
                              : index === 1
                                ? "Finalize the payment after providing the necessary details"
                                : index === 2
                                  ? "Experienced pandits conduct your pooja and receive video of pooja"
                                  : "Get blessings and prasad delivered to your home"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
      <Footer />
    </div>
  );
}
function generateRandomNumbers() {
  return Math.floor(Math.random() * 19) + 2;
}
export default PujaPage;
