import React, { useState, useEffect } from 'react';
import styles from '../styles/components/CountdownTimer.module.css';

//2024-10-31T10:00:00 -  example of timer_date field in firebase db
const CountdownTimer = ({ targetDate }) => {
    const [timeRemaining, setTimeRemaining] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  
    useEffect(() => {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = new Date(targetDate).getTime() - now;
  
        if (distance < 0) {
          clearInterval(interval);
          setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        } else {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
          setTimeRemaining({ days, hours, minutes, seconds });
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }, [targetDate]);
  
    return (
      <div className={styles.countdown_timer}>
        <div className={styles.countdown_item}>
          <div className={styles.countdown_value}>{timeRemaining.days}</div>
          <div className={styles.countdown_label}>Days</div>
        </div>
        <div className={styles.countdown_item}>
          <div className={styles.countdown_value}>{timeRemaining.hours}</div>
          <div className={styles.countdown_label}>Hrs</div>
        </div>
        <div className={styles.countdown_item}>
          <div className={styles.countdown_value}>{timeRemaining.minutes}</div>
          <div className={styles.countdown_label}>Min</div>
        </div>
        <div className={styles.countdown_item}>
          <div className={styles.countdown_value}>{timeRemaining.seconds}</div>
          <div className={styles.countdown_label}>Sec</div>
        </div>
      </div>
    );
  };
  
  export default CountdownTimer;