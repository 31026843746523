import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../styles/screens/Referral.module.css";

const General = ({ pujaCount, bonus }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.balance_container}>
      <div className={styles.balance_friends}>
        <p style={{ color: "#ff8e4d" }}>{t("People Referred for Puja:")}</p>
        <div className={styles.balance_text}>
          <p>{pujaCount}</p>
        </div>
      </div>
      <div className={styles.balance_friends}>
        <p style={{ color: "#ff8e4d" }}>{t("Your balance:")}</p>
        <div className={styles.balance_text}>
          <p>{bonus}</p>
        </div>
      </div>
    </div>
  );
};

export default General;
