function generateReferralCode(phoneNumber) {
  const relevantDigits = phoneNumber.slice(-9);
  const base36Digits = parseInt(relevantDigits) % Math.pow(36, 6);
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let referralCode = "";
  let remainder = base36Digits;

  for (let i = 0; i < 6; i++) {
    referralCode = charset[remainder % 26] + referralCode;
    remainder = Math.floor(remainder / 26);
  }

  return referralCode;
}

export default generateReferralCode;
