import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../styles/screens/Profile/SavedAddress.module.css";
import AddAddressDialog from "../../components/AddAddress";
import firebaseConfig from "../../firebase";
import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";

const SavedAddress = () => {
  const { t } = useTranslation();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const phoneNumber = JSON.parse(localStorage.getItem("phoneNumber"));
  const [showDialog, setShowDialog] = useState(false);
  const [addressData, setAddressData] = useState();
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const savedAddressRef = doc(db, "saved_address", phoneNumber);
        const querySnapshot = await getDoc(savedAddressRef);
        setAddressData(querySnapshot.data());
        if (querySnapshot.data()) setFetched(true);
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    };

    fetchAddress();
  }, [db, phoneNumber, showDialog]);

  const removeAddress = async (addressName) => {
    const addressRef = doc(db, "saved_address", phoneNumber);
    let updatedAddressData = { ...addressData };
    delete updatedAddressData[addressName];
    try {
      await setDoc(addressRef, updatedAddressData);
      setAddressData(updatedAddressData);
    } catch (error) {
      console.error("Error removing address:", error);
    }
  };

  return (
    <div>
      <h1
        style={{
          color: "#EB894E",
          marginLeft: "20px",
          fontWeight: "normal",
        }}
      >
        {t("Saved Address")}
      </h1>
      <div className={styles.saved_address_container}>
        <div className={styles.saved_address}>
          {fetched &&
            Object.values(addressData).map((address) => (
              <div key={address.addressName} className={styles.addressBox}>
                <span className={styles.addressName}>
                  {address.addressName}
                </span>
                <div className={styles.addressDetails}>
                  <span>{address.city}</span>
                  <span>{address.house}</span>
                  <span>{address.locality}</span>
                  <span>{t("Pincode") + " : " + address.pincode}</span>
                </div>
                <span className={styles.phoneNumber}>
                  {t("Phone Number") + " : " + phoneNumber}
                </span>
                <button
                  onClick={() => removeAddress(address.addressName)}
                  className={styles.remove_button}
                >
                  {t("Remove Address")}
                </button>
              </div>
            ))}
        </div>
        <button
          onClick={() => setShowDialog(true)}
          className={styles.download_button}
        >
          {t("Add New Address")}
        </button>
        {showDialog && (
          <AddAddressDialog
            isOpen={showDialog}
            onClose={() => setShowDialog(false)}
          />
        )}
      </div>
    </div>
  );
};

export default SavedAddress;
