function calculateAppMoneyEarned(downloads) {
  let totalMoney = 0;

  if (downloads <= 100) {
    totalMoney = downloads * 5;
  } else if (downloads <= 200) {
    totalMoney = 100 * 5 + (downloads - 100) * 6;
  } else if (downloads <= 300) {
    totalMoney = 100 * 5 + 100 * 6 + (downloads - 200) * 7;
  } else if (downloads <= 400) {
    totalMoney = 100 * 5 + 100 * 6 + 100 * 7 + (downloads - 300) * 8;
  } else if (downloads <= 500) {
    totalMoney = 100 * 5 + 100 * 6 + 100 * 7 + 100 * 8 + (downloads - 400) * 9;
  } else if (downloads <= 600) {
    totalMoney =
      100 * 5 + 100 * 6 + 100 * 7 + 100 * 8 + 100 * 9 + (downloads - 500) * 10;
  } else {
    totalMoney =
      100 * 5 +
      100 * 6 +
      100 * 7 +
      100 * 8 +
      100 * 9 +
      100 * 10 +
      (downloads - 600) * 10;
  }

  return totalMoney;
}

export default calculateAppMoneyEarned;
