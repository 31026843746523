import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../styles/components/Download.module.css";

import GoogleImage from "../assets/google.png";
import temple from "../assets/ic_temple.png";
import posthog from "posthog-js";

function Download() {
  const { t } = useTranslation();

  return (
    <div className={styles.explore}>
      <div className={styles.header}>
        <div className={styles.icon_text_wrapper}>
          <img src={temple} alt="Puja Book Icon" className={styles.icon}></img>
          <h2 className={styles.title}>{t("Download the App Now")}</h2>
        </div>
      </div>
      <div className={styles.list}>
        <div
          className={styles.card}
          onClick={() => {
            posthog?.capture('download_button_clicked', {
              homepage_playstore_button: 'Download button cliked',
            });

            window.open(
              "https://dharmikapp.co/webiste-download"
            )
          }
          }
        >
          <img src={GoogleImage} alt={"Google"} className={styles.gimage} />
        </div>
      </div>
    </div>
  );
}

export default Download;
