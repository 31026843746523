import React, { useState, useEffect } from "react";
import NavBar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import styles from "../../styles/screens/Ayodhya/Payment.module.css";
import templeIcon from "../../assets/ic_temple.png";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import firebaseConfig from "../../firebase";
import SummaryCard from "../../components/Payment/SummaryCard";
import getOrderId from "../../utils/OrderId";
import UpdateOrderFB from "../../components/Payment/UpdateOrderFB";
import openRazorpay from "../../components/Payment/Razorpay";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function Payment2() {
  const { group, id } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [pujaDetails, setPujaDetails] = useState(null);
  const [name, setName] = useState(
    JSON.parse(localStorage.getItem("name"))
      ? JSON.parse(localStorage.getItem("name"))
      : ""
  );
  const phone = JSON.parse(localStorage.getItem("phoneNumber"));
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("email"))
      ? JSON.parse(localStorage.getItem("email"))
      : ""
  );
  const [coupon, setCoupon] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [house, setHouse] = useState("");
  const [locality, setLocality] = useState("");
  const [city, setCity] = useState("");
  const [gotra, setGotra] = useState("");
  const [pincode, setPincode] = useState("");
  const [language, setLanguage] = useState("en");
  const [isCOD, setIsCod] = useState(false);
  const { t, i18n } = useTranslation();
  const [orderId, setOrderId] = useState("");
  const [pujaPrice, setPujaPrice] = useState(0);
  const referral_code = localStorage.getItem("referral_code");
  const userType = localStorage.getItem("userType");
  const [showCouponButton, setShowCouponButton] = useState(true);

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const fetchPujaDetails = async () => {
      const pujaDocRef = collection(db, "poojaplaces", group, "offerings");
      const pujaQuerySnapshot = await getDocs(pujaDocRef);

      var referralAmount = 0;
      // var reff;
      // if (userType === "merchant") reff = "merchant_referral_db";
      // else reff = "referral_db";

      // const referredRef = doc(db, reff, referral_code);
      // const referredSnapshot = await getDoc(referredRef);

      // if (referredSnapshot.exists()) {
      //   referralAmount = referredSnapshot.data().merchant_bonus_amount || 0;
      //   console.log(referralAmount);
      //   await updateDoc(referredRef, {
      //     merchant_bonus_amount: 0,
      //   });
      // }

      try {
        pujaQuerySnapshot.forEach(async (pujaDocument) => {
          const offeringData = pujaDocument.data();
          if (offeringData.offeringId === id) {
            setPujaDetails(offeringData);
            setPujaPrice(
              (offeringData.price.discounted_price < offeringData.price.en
                ? offeringData.price.discounted_price
                : offeringData.price.en) - referralAmount
            );
            setOrderId(
              await getOrderId(
                (offeringData.price.discounted_price < offeringData.price.en
                  ? offeringData.price.discounted_price
                  : offeringData.price.en) - referralAmount
              )
            );
          }
        });
      } catch (error) {
        console.error("Error getting documents:", error);
      }
    };

    fetchPujaDetails();
  }, [group, id, referral_code, userType]);

  useEffect(() => {
    if (pujaDetails) {
      setShowCouponButton(true);
      if (paymentMethod === "cod") {
        setPujaPrice(
          parseInt(
            pujaDetails.price.discounted_price < pujaDetails.price.en
              ? pujaDetails.price.discounted_price
              : pujaDetails.price.en
          ) + 50
        );
        setCouponDiscount(0);
        setIsCod(true);
      } else {
        setIsCod(false);
        setCouponDiscount(0);
        setPujaPrice(
          parseInt(
            pujaDetails.price.discounted_price < pujaDetails.price.en
              ? pujaDetails.price.discounted_price
              : pujaDetails.price.en
          )
        );
      }
    }
  }, [paymentMethod, pujaDetails]);

  if (!pujaDetails) {
    return <div>Loading...</div>;
  }

  const onClickBook = async (e) => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);
    setPujaPrice(pujaPrice + parseInt(isCOD ? 50 : 0));

    if (!orderId) {
      console.log("No order id");
    }

    try {
      const orderRef = doc(db, "orders", orderId);
      const skuName = ((paymentMethod === "online" ? (pujaDetails && pujaDetails.sku && pujaDetails.sku.prepaid && pujaDetails.sku.prepaid.name) : (pujaDetails && pujaDetails.sku && pujaDetails.sku.cod && pujaDetails.sku.cod.name)) || '');
      const skuCode = ((paymentMethod === "online" ? (pujaDetails && pujaDetails.sku && pujaDetails.sku.prepaid && pujaDetails.sku.prepaid.code) : (pujaDetails && pujaDetails.sku && pujaDetails.sku.cod && pujaDetails.sku.cod.code)) || '');
      await setDoc(orderRef, {
          name,
          gotra,
          phone,
          address: {
            house,
            locality,
            city,
            pincode,
          },
          coupon,
          platform: "web",
          pujaname: pujaDetails.name?.en,
          pujadescription: pujaDetails.description?.en,
          price: pujaPrice,
          orderId: orderId,
          offeringId: id,
          image: pujaDetails.image,
          quantity: '1',
          status: "PAYMENT_NOT_INITIATED",
          created_time: serverTimestamp(),
          updated_time: serverTimestamp(),
          skuname: skuName,
          skucode: skuCode,
        }
      );
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    UpdateOrderFB(group, orderId, paymentMethod, phone, id);

    if (paymentMethod === "online")
      openRazorpay(
        group,
        orderId,
        pujaPrice,
        id,
        phone,
        pujaDetails.name?.en,
        email,
        name
      );
  };

  const onApplyCoupon = async () => {
    const couponsDocRef = collection(db, "coupons");
    const couponsQuerySnapshot = await getDocs(couponsDocRef);

    try {
      const orderPromises = couponsQuerySnapshot.docs.map(
        async (couponDocument) => {
          const couponData = couponDocument.data();
          if (couponData.coupon === coupon && couponData.operational) {
            const tmp =
              (parseFloat(couponData.discount.percentage) / 100) * pujaPrice;
            const discount = Math.round(
              tmp < parseFloat(couponData.discount.max)
                ? tmp
                : couponData.discount.max
            );
            const tmpPrice = pujaPrice - discount;

            setOrderId(await getOrderId(tmpPrice));
            setShowCouponButton(false);
            setCouponDiscount(discount);
            setPujaPrice(tmpPrice);
          }
        }
      );

      const orderIds = await Promise.all(orderPromises);
      const validOrderIds = orderIds.filter((orderId) => orderId);
      if (validOrderIds.length > 0) {
        return validOrderIds[0];
      }
    } catch (error) {
      console.error("Error getting coupon:", error);
      return null;
    }
  };

  return (
    <div className={styles.ayodhya}>
      <NavBar />
      <main className={styles.mainContent}>
        <section className={styles.features}>
          <header className={styles.header}>
            <div className={styles.iconTextWrapper}>
              <img src={templeIcon} alt="Temple Icon" className={styles.icon} />
              <h1 className={styles.title}>
                {language === "en"
                  ? pujaDetails.name?.en
                  : pujaDetails.name?.hi}
              </h1>
            </div>
            {pujaDetails.description?.en && (
              <p className={styles.subtitle}>
                {language === "en"
                  ? pujaDetails.description?.en
                  : pujaDetails.description?.hi}
              </p>
            )}
          </header>
          <form onSubmit={onClickBook}>
            <h2>{t("Details for Prasad Delivery")}</h2>
            <div className={styles.formGroup}>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => {
                  if (!submitting) setName(e.target.value);
                }}
                placeholder={t("Name")}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                value={gotra}
                onChange={(e) => {
                  if (!submitting) setGotra(e.target.value);
                }}
                placeholder={t("Gotra") + t(" (optional)")}
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={phone}
                placeholder={t("Phone Number")}
                readOnly
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => {
                  if (!submitting) setEmail(e.target.value);
                }}
                placeholder={t("Email") + t(" (optional)")}
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                value={house}
                onChange={(e) => {
                  if (!submitting) setHouse(e.target.value);
                }}
                placeholder={t("House Name/Number")}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                value={locality}
                onChange={(e) => {
                  if (!submitting) setLocality(e.target.value);
                }}
                placeholder={t("Locality/Street")}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                value={city}
                onChange={(e) => {
                  if (!submitting) setCity(e.target.value);
                }}
                placeholder={t("City/Town")}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="tel"
                id="pincode"
                name="pincode"
                value={pincode}
                onChange={(e) => {
                  const input = e.target.value;
                  const numbersOnly = input.replace(/\D/g, "");
                  if (!submitting) setPincode(numbersOnly);
                }}
                placeholder={t("Pin Code")}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  id="code"
                  value={coupon}
                  onChange={(e) => {
                    if (!submitting) setCoupon(e.target.value);
                  }}
                  placeholder={t("Enter coupon code")}
                />
                {showCouponButton && (
                  <button
                    type="button"
                    onClick={onApplyCoupon}
                    className={styles.couponButton}
                  >
                    {t("Apply Coupon")}
                  </button>
                )}
              </div>
            </div>
            <div className={styles.radioGroup}>
              <label htmlFor="paymentMethod" style={{ marginRight: "10px" }}>
                {t("Payment Method: ")}
              </label>
              <div className={styles.cardContainer}>
                <div className={styles.cardItem}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    value="online"
                    checked={paymentMethod === "online"}
                    onChange={(e) => {
                      if (!submitting) setPaymentMethod(e.target.value);
                    }}
                  />
                  <label htmlFor="online">{t("Online Payment")}</label>
                </div>
                <div className={styles.cardItem}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    value="cod"
                    checked={paymentMethod === "cod"}
                    onChange={(e) => {
                      if (!submitting) setPaymentMethod(e.target.value);
                    }}
                  />
                  <label htmlFor="cod">{t("Cash On Delivery")}</label>
                </div>
              </div>
            </div>
            {paymentMethod === "online" ? (
              <button className={styles.bookButton}>
                {!submitting ? t("Make Payment") : t("Loading...")}
              </button>
            ) : (
              <button className={styles.bookButton}>
                {!submitting ? t("Book Order") : t("Loading...")}
              </button>
            )}
          </form>
        </section>
        <aside className={styles.listing}>
          <SummaryCard
            pujaDetails={pujaDetails}
            pujaPrice={pujaPrice}
            isCOD={isCOD}
            couponDiscount={couponDiscount}
            language={language}
          />
        </aside>
      </main>

      <Footer />
    </div>
  );
}

export default Payment2;
