import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import firebaseConfig from "../../firebase";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const UpdateOrderFB = async (group, orderId, paymentMethod, phone, id) => {
  if (paymentMethod === "cod") {
    try {
      const orderRef = doc(db, "orders", orderId);
      const orderSnapshot = await getDoc(orderRef);
      const orderData = orderSnapshot.data();

      if (orderSnapshot.exists()) {
        await setDoc(orderRef, {[orderId]:{
          ...orderData,
          status: "BOOKED_COD",
          paymentMethod: "cod",
          updated_time: serverTimestamp(),
        } });

        const orderCodRef = doc(db, "cod_order", orderId);
        await setDoc(orderCodRef, {[orderId]:{
          ...orderData,
          status: "BOOKED_COD",
          paymentMethod: "cod",
          created_time: serverTimestamp(),
          updated_time: serverTimestamp(),
        }});

        const orderPendingRef = doc(db, "order_history", phone);
        const orderHistorySnapshot = await getDoc(orderPendingRef);
        if (orderHistorySnapshot.exists()) {
          await setDoc(orderPendingRef, {
            [orderId]: {
              ...orderData,
              status: "BOOKED_COD",
              paymentMethod: "cod",
              created_time: serverTimestamp(),
              updated_time: serverTimestamp(),
            },
          }, {merge: true});
        } else {
          await setDoc(orderPendingRef, {
            [orderId]: {
              ...orderData,
              status: "BOOKED_COD",
              paymentMethod: "cod",
              created_time: serverTimestamp(),
              updated_time: serverTimestamp(),
            },
          });
        }
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    window.location.href =
      "/payment/result/" + group + "/" + id + "/" + orderId;
    return;
  }

  try {
    const orderRef = doc(db, "orders", orderId);
    const orderSnapshot = await getDoc(orderRef);
    const orderData = orderSnapshot.data();

    if (orderSnapshot.exists()) {
      await setDoc(orderRef, {[orderId]:{
        ...orderData,
        status: "PAYMENT_PENDING",
        paymentMethod: "online",
        updated_time: serverTimestamp(),
      }});

      const orderCodRef = doc(db, "pending_order", orderId);
      await setDoc(orderCodRef, {[orderId]:{
        ...orderData,
        status: "PAYMENT_PENDING",
        paymentMethod: "online",
        created_time: serverTimestamp(),
        updated_time: serverTimestamp(),
      }});

      const orderPendingRef = doc(db, "order_history", phone);
      const orderHistorySnapshot = await getDoc(orderPendingRef);
      if (orderHistorySnapshot.exists()) {
        //console.log("Pushing into Order History of:", phone, "/t orderId: ", orderId);
        await setDoc(orderPendingRef, {
          [orderId]: {
            ...orderData,
            status: "PAYMENT_PENDING",
            paymentMethod: "online",
            created_time: serverTimestamp(),
            updated_time: serverTimestamp(),
          },
        }, {merge: true});
      } else {
        //console.log("Pushing into Order History of:", phone, "/t orderId: ", orderId);
        await setDoc(orderPendingRef, {
          [orderId]: {
            ...orderData,
            status: "PAYMENT_PENDING",
            paymentMethod: "online",
            created_time: serverTimestamp(),
            updated_time: serverTimestamp(),
          },
        });
      }
    }
  } catch (e) {
    console.error("Error adding document: ", e);
  }
  return;
};

export default UpdateOrderFB;
