import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../styles/screens/Referral.module.css";
import calculateAppMoneyEarned from "../../utils/AppSignUPMoney";

const Merchant = ({ appReferralCount, pujaCount, bonus }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.balance_container}>
        <div className={styles.referral_code}>
          <div className={styles.balance_friends}>
            <p style={{ color: "#ff8e4d", fontWeight: "bold" }}>
              {t("People Referred for App:")}
            </p>
            <div className={styles.mbalance_text}>
              <p>{appReferralCount}</p>
            </div>
            <div className={styles.m_text}>
              <p>
                {t("Earnings: ")}
                <b>{"₹" + calculateAppMoneyEarned(appReferralCount)}</b>
              </p>
            </div>
          </div>
          <div className={styles.balance_friends}>
            <p style={{ color: "#ff8e4d", fontWeight: "bold" }}>
              {t("People Referred for Puja:")}
            </p>
            <div className={styles.mbalance_text}>
              <p>{pujaCount}</p>
            </div>
            <div className={styles.m_text}>
              {t("Earnings: ")}
              <b>{"₹" + bonus}</b>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.balance_container}>
        <div className={styles.referral_total}>
          <div className={styles.balance_total}>
            <p
              style={{
                color: "#ff8e4d",
              }}
            >
              {t("Your balance =") +
                " ₹ " +
                (bonus + calculateAppMoneyEarned(appReferralCount))}
            </p>
            {/* <button className={styles.withdraw_button}>{t("Withdraw")}</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merchant;
