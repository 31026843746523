import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useNavigate} from "react-router-dom";
function DiscountBanner() {
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const currentDate = new Date();
  const hoursLeft = 23 - currentDate.getHours();
  const minutesLeft = 60 - currentDate.getMinutes();
  const timeLeft = hoursLeft + " hours " + minutesLeft + " minutes";
  const [hibannertext, setHibannertext] = useState("");
  const [enbannertext, setEnbannertext] = useState("");
  const [link, setLink] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBannertext = async () => {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const bannertextCollection = collection(db, "bannertext");
      const bannertextSnapshot = await getDocs(bannertextCollection);
      const bannertextData = bannertextSnapshot.docs.map((doc) => doc.data());
      if(bannertextData[0].operational) {
      setEnbannertext(bannertextData[0].banner.en);
      setHibannertext(bannertextData[0].banner.hi);
      setLink(bannertextData[0].link);
      }
    };

    fetchBannertext();
  }, [enbannertext, hibannertext]);

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  const handleClick = () => {
    navigate(link);
  }

  if(enbannertext === "" || hibannertext === "")
    return (<></>);

  return (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
    <Marquee
      pauseOnHover={true}
      speed={150}
      delay={1}
      style={{
        backgroundColor: "#ff8e4d",
        color: "white",
        fontSize: "1rem",
        padding: "2px 0",
      }}
    >
      {language === "en" ? enbannertext : hibannertext}
      {" | " + t("Offer ends within: ") + timeLeft + " | "}
    </Marquee>
    </div>
  );
}

export default DiscountBanner;
