import React, { useState, useEffect } from "react";
import styles from "../../styles/screens/Profile/Profile.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import firebaseConfig from "../../firebase";

import { initializeApp } from "firebase/app";
import {
  getDoc,
  getFirestore,
  updateDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";

const BasicDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const [name, setName] = useState("");
  const phoneNumber = JSON.parse(localStorage.getItem("phoneNumber"));
  const userType = localStorage.getItem("userType");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("male");
  const [dob, setDob] = useState("2002-12-12");
  const [city, setCity] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      var reff;
      if (userType === "merchant") reff = "merchants";
      else reff = "users";

      const userRef = doc(db, reff, phoneNumber);
      const userSnapshot = await getDoc(userRef);
      const user = userSnapshot.data();
      if (user.userName) {
        setName(user.userName);
        setEmail(user.email);
        setGender(user.gender);
        setDob(user.dob);
        setCity(user.city);
      }
    };

    fetchUsers();
  }, [db, phoneNumber, userType]);

  const handleInputChange = (e, field) => {
    if (field === "name") setName(e.target.value);
    if (field === "email") setEmail(e.target.value);
    if (field === "gender") setGender(e.target.value);
    if (field === "dob") setDob(e.target.value);
    if (field === "city") setCity(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    localStorage.setItem("name", JSON.stringify(name));
    localStorage.setItem("email", JSON.stringify(email));

    const userRef = doc(db, "users", phoneNumber);
    await updateDoc(userRef, {
      userName: name,
      email: email,
      phoneNumber: phoneNumber,
      gender: gender || "male",
      dob: dob,
      city: city,
      updateTime: serverTimestamp(),
    });

    navigate("/");
  };

  return (
    <div>
      <h1
        style={{ color: "#EB894E", marginLeft: "20px", fontWeight: "normal" }}
      >
        {t("Basic Details")}
      </h1>
      <form onSubmit={handleSubmit} className={styles.profileForm}>
        <input
          type="text"
          id="name"
          value={name}
          required
          onChange={(e) => handleInputChange(e, "name")}
          placeholder={t("Enter your name")}
        />
        <input
          type="email"
          id="email"
          value={email}
          required
          onChange={(e) => handleInputChange(e, "email")}
          placeholder={t("Enter your email")}
        />
        <input type="text" id="phone" value={phoneNumber} readOnly />
        <select
          id="gender"
          required
          value={gender}
          onChange={(e) => handleInputChange(e, "gender")}
        >
          <option value="male">{t("Male")}</option>
          <option value="female">{t("Female")}</option>
        </select>
        <label htmlFor="dob" style={{ marginBottom: "10px" }}>
          {t("Date of Birth")}
        </label>
        <input
          type="date"
          id="dob"
          value={dob}
          required
          onChange={(e) => handleInputChange(e, "dob")}
          placeholder={t("Enter your date of birth")}
        />
        <input
          type="text"
          id="address"
          value={city}
          required
          onChange={(e) => handleInputChange(e, "city")}
          placeholder={t("Enter your current city")}
        />
        <button type="submit" className={styles.submitButton}>
          {t("Save Changes")}
        </button>
      </form>
    </div>
  );
};

export default BasicDetails;
