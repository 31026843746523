import React, { useState, useEffect } from "react";
import styles from "../../styles/screens/Ayodhya/Ayodhya.module.css";
import { useTranslation } from "react-i18next";
import PujaCard from "../../components/PujaCard";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import firebaseConfig from "../../firebase";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function SavedPuja() {
  const [pujas, setPujas] = useState([]);
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const [areThereAnyPujas, setAreThereAnyPujas] = useState(false);
  const phone = JSON.parse(localStorage.getItem("phoneNumber"))
    ? JSON.parse(localStorage.getItem("phoneNumber"))
    : "";

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const fetchPujas = async () => {
      const pujasCollection = collection(db, "poojaplaces");
      const ayodhyaQuery = query(
        pujasCollection,
        where("place.en", "==", "Ayodhya")
      );
      const ayodhyaSnapshot = await getDocs(ayodhyaQuery);
      if (!ayodhyaSnapshot.empty) {
        const savedRef = doc(db, "saved_puja", phone, "1", "saved_pujas");
        const savedDoc = await getDoc(savedRef);
        var savedData = [];

        if (savedDoc.exists()) {
          savedData = savedDoc.data();
        }

        const ayodhyaDoc = ayodhyaSnapshot.docs[0];
        const offeringsCollection = collection(
          db,
          "poojaplaces",
          ayodhyaDoc.id,
          "offerings"
        );
        const offeringsSnapshot = await getDocs(offeringsCollection);
        const offeringsData = offeringsSnapshot.docs.map((doc) => {
          const data = doc.data();
          if (savedData[doc.id]) {
            setAreThereAnyPujas(true);
            return {
              id: doc.id,
              offeringId: data.offeringId,
              name: language === "en" ? data.name?.en : data.name?.hi,
              path: data.offeringName,
              price: data.price?.en,
              rating: data.rating,
              trending: data.trending,
              booked_by: data.booked_by,
              benefits: data.benefit_label,
              discounted_price: data.price?.discounted_price,
              imageUrl: data.image,
            };
          }
          return [];
        });
        setPujas(offeringsData);
      }
    };

    fetchPujas();
  }, [language, phone]);

  return (
    <div className={styles.ayodhya}>
      <ToastContainer />
      <h1
        style={{ color: "#EB894E", marginLeft: "20px", fontWeight: "normal" }}
      >
        {t("Saved Pujas")}
      </h1>
      <div className={styles.list}>
        {pujas.length > 0 &&
          pujas.map(
            (puja) =>
              puja.offeringId && (
                <PujaCard
                  key={puja.id}
                  id={puja.offeringId}
                  name={puja.name}
                  price={puja.price}
                  path={puja.path}
                  rating={puja.rating}
                  trending={puja.trending}
                  booked_by={puja.booked_by}
                  benefits={
                    language === "en" ? puja.benefits?.en : puja.benefits?.hi
                  }
                  discounted_price={
                    puja.discounted_price ? puja.discounted_price : -1
                  }
                  imageUrl={puja.imageUrl}
                />
              )
          )}
        {!areThereAnyPujas && (
          <h3
            style={{
              fontWeight: "normal",
              marginLeft: "20px",
            }}
          >
            {t("No Saved Pujas")}
          </h3>
        )}
      </div>
    </div>
  );
}

export default SavedPuja;
