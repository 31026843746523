import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../styles/screens/Referral.module.css";
import instaIcon from "../../assets/sm_icon/insta.png";
import fbIcon from "../../assets/sm_icon/fb.png";
import twitterIcon from "../../assets/sm_icon/twitter.png";
import whatsappIcon from "../../assets/sm_icon/whatsapp.png";
import iIcon from "../../assets/referral/i_button.png";
import NavBar from "../../components/Navbar";
import Footer from "../../components/Footer";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import firebaseConfig from "../../firebase";
import MiddlePart from "../../components/Referral/MiddlePart";
import General from "../../components/Referral/General";
import Merchant from "../../components/Referral/Merchant";
import { usePostHog } from 'posthog-js/react'

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Referral = () => {
  const navigate = useNavigate();
  const phoneNumber = JSON.parse(localStorage.getItem("phoneNumber"));
  const [bonus, setBonus] = useState(0);
  const [pujaCount, setPujaCount] = useState(0);
  const [appReferralCount, setAppReferralCount] = useState(0);
  const userType = localStorage.getItem("userType");
  const referralId = localStorage.getItem("referral_code");
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const posthog = usePostHog();

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    async function fetchData() {
      var reff;
      var referralReff;
      if (userType === "merchant") {
        reff = "merchants";
        referralReff = "merchant_referral_db";
      } else {
        reff = "users";
        referralReff = "referral_db";
      }

      const userRef = doc(db, reff, phoneNumber);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const referralIdValue = userData.referral_code;

        const referralRef = doc(db, referralReff, referralIdValue);
        const referralSnapshot = await getDoc(referralRef);
        if (referralSnapshot.exists()) {
          const prevData = referralSnapshot.data();
          setBonus(
            prevData.merchant_bonus_amount ? prevData.merchant_bonus_amount : 0
          );
          setPujaCount(
            prevData.referred_booking ? prevData.referred_booking.length : 0
          );
          setAppReferralCount(
            prevData.app_signup_count ? prevData.app_signup_count : 0
          );
        }
      }
    }
    fetchData();
  }, [phoneNumber, referralId, userType]);

  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);

  const copyReferralCode = (str) => {
    const hitext =
      "अपने दरवाजे पर दिव्य आशीर्वाद प्राप्त करें! 🕉️ मेरे रेफरल कोड *" +
      referralId +
      "* का उपयोग करें और Dharmik ऐप डाउनलोड करें, भारत भर के मंदिरों से पूजा बुक करें और प्रसाद प्राप्त करें। पहले बुकिंग पर 10% का छूट प्राप्त करें। 🌟 घर पर दिव्य आशीर्वाद का अनुभव करें! 🙏 \nऐप लिंक: https://dharmikapp.co/share-playstore-web";
    const engText =
      "Unlock divine blessings at your doorstep! 🕉️ Use my referral code *" +
      referralId +
      "* to download the Dharmik app, book personalized pujas, and receive blessed prasad from temples across India. Enjoy a 10% discount on your first booking. 🌟 Experience spirituality like never before! 🙏 \nApp link: https://dharmikapp.co/share-playstore-web";

    const url = language === "en" ? engText : hitext;
    const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    const instaShareUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
      url
    )}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url
    )}`;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      url
    )}`;

    if (str === "fb") {
      window.open(fbShareUrl, "_blank");
    } else if (str === "insta") {
      window.open(instaShareUrl, "_blank");
    } else if (str === "twitter") {
      window.open(twitterShareUrl, "_blank");
    } else if (str === "wa") {
      window.open(whatsappShareUrl, "_blank");
    }
  };

  return (
    <div>
      <NavBar />
      <div className={styles.referral}>
        <div
          className={styles.top_part}
          id={userType === "general" ? styles.general : styles.merchant}
        >
          <span className={styles.top_text}>
            {userType === "general"
              ? t("Refer and Earn")
              : t("Partner Program")}
          </span>
          <span className={styles.top_subtext}>
            {userType === "general"
              ? t(
                  "Refer your friend and earn 10% (upto Rs. 500) on the puja booked by them, Your friends also get 10% off."
                )
              : t(
                  "Refer customers and earn money on every download/puja booked."
                )}
            {userType === "merchant" && (
              <img
                src={iIcon}
                alt="i"
                onClick={() => navigate("/referral-policy")}
                style={{ width: "1rem" }}
              />
            )}
          </span>
        </div>
        <MiddlePart userType={userType} />
        <div className={styles.bottom_part}>
          <h2>
            {t("Hi, ") + (userType === "general" ? phoneNumber : t("Partner"))}
          </h2>{" "}
          <h5>
            {userType === "general"
              ? t(
                  "Refer your friend and earn 10% (upto Rs. 500) on the puja booked by them, Your friends also get 10% off."
                )
              : t(
                  "Refer customers and earn upto Rs. 500 for each customer onboarded"
                )}
          </h5>{" "}
          <div className={styles.referral_code_container}>
            <div className={styles.referral_code}>
              <input
                type="text"
                id="referralCode"
                value={referralId}
                readOnly
              />
              <span className={styles.spacing}></span>
              <span>{t("or")}</span>
              <span className={styles.spacing}></span>
              <img
                src={fbIcon}
                onClick={() => copyReferralCode("fb")}
                alt={t("share")}
              />
              <span className={styles.spacing}></span>
              <img
                src={instaIcon}
                onClick={() => copyReferralCode("insta")}
                alt={t("share")}
              />
              <span className={styles.spacing}></span>
              <img
                src={twitterIcon}
                onClick={() => copyReferralCode("twitter")}
                alt={t("share")}
              />
              <span className={styles.spacing}></span>
              <img
                src={whatsappIcon}
                onClick={() => copyReferralCode("wa")}
                alt={t("share")}
              />
            </div>
          </div>
          {userType === "general" ? (
            <General pujaCount={pujaCount} bonus={bonus} />
          ) : (
            <Merchant
              appReferralCount={appReferralCount}
              pujaCount={pujaCount}
              bonus={bonus}
            />
          )}
        </div>
        <h5
          style={{
            paddingLeft: "2rem",
            paddingBottom: "2rem",
            marginBottom: "0rem",
          }}
        >
          {t("Please read Referral policies for detailed terms and conditions")}
        </h5>
      </div>
      <Footer />
    </div>
  );
};

export default Referral;
