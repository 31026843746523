import React from "react";
import styles from "../styles/components/PujaReview.module.css";

const PujaReview = ({ name, image, city, comment, rating }) => {
  return (
    <div className={styles.pujaReview}>
      <div className={styles.pujaReviewContent}>
        <div className={styles.pujaReviewHeader}>
          <div className={styles.pujaReviewImageContainer}>
            <img src={image} alt={name} className={styles.pujaReviewImage} />
          </div>
          <div className={styles.pujaReviewInfo}>
            <p className={styles.pujaReviewName}>{name}</p>
            <p className={styles.pujaReviewCity}>{city}</p>
          </div>
        </div>
        <div className={styles.pujaReviewComment}>
          <p>{comment}</p>
        </div>
      </div>
    </div>
  );
};

export default PujaReview;
