import React, {useEffect} from "react";
import NavBar from "../components/Navbar";
import styles from "../styles/screens/AboutUs.module.css";
import temple from "../assets/ic_temple.png";
import Footer from "../components/Footer";

import adarshImage from "../assets/team/adarsh.png";
import adityaImage from "../assets/team/aditya.png";
import sanklapImage from "../assets/team/sanklap.png";
import { useTranslation } from "react-i18next";
import { usePostHog } from 'posthog-js/react'

const AboutUs = () => {
  const { t } = useTranslation();
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);

  return (
    <div>
      <NavBar />
      <div style={{ backgroundColor: "#fff1ea" }}>
        <div className={styles.icon_text}>
          <img
            src={temple}
            alt={t("Temple Icon")}
            className={styles.icon}
          ></img>
          <h1 className={styles.title}>{t("About Us")}</h1>
        </div>
        <div className={styles._text}>
          <p>{t("About Us Description")}</p>
          <h2>{t("Spiritual Journey Heading")}</h2>
          <p>{t("Spiritual Journey Description")}</p>
        </div>
        <div className={styles.icon_text}>
          <img
            src={temple}
            alt={t("Temple Icon")}
            className={styles.icon}
          ></img>
          <h1 className={styles.title}>{t("Meet Our Team")}</h1>
        </div>
        <div className={styles.teamProfiles}>
          <div className={styles.teamProfile}>
            <img
              src={adarshImage}
              alt={t("Adarsh Goyal Alt")}
              className={styles.profileImage}
            />
            <h3>{t("Adarsh Goyal Name")}</h3>
            <p>{t("Adarsh Goyal Title")}</p>
            <p>{t("Adarsh Goyal Description")}</p>
          </div>
          <div className={styles.teamProfile}>
            <img
              src={adityaImage}
              alt={t("Aditya Rana Alt")}
              className={styles.profileImage}
            />
            <h3>{t("Aditya Rana Name")}</h3>
            <p>{t("Aditya Rana Title")}</p>
            <p>{t("Aditya Rana Description")}</p>
          </div>
          <div className={styles.teamProfile}>
            <img
              src={sanklapImage}
              alt={t("Sanklap Asawa Alt")}
              className={styles.profileImage}
            />
            <h3>{t("Sanklap Asawa Name")}</h3>
            <p>{t("Sanklap Asawa Title")}</p>
            <p>{t("Sanklap Asawa Description")}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
