import React, { useState, useEffect } from "react";
import NavBar from "../../components/Navbar";
import styles from "../../styles/screens/Ayodhya/Pujapage.module.css";
import templeIcon from "../../assets/ic_temple.png";
import Footer from "../../components/Footer";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import firebaseConfig from "../../firebase";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PujaReview from "../../components/PujaReview";
import Step1Icon  from "../../assets/step1.svg";
import Step2Icon  from "../../assets/step2.svg";
import Step3Icon  from "../../assets/step3.svg";
import Step4Icon  from "../../assets/step4.svg";
import LivetimerDetails from "../../components/LivetimerDetails";

const app = initializeApp(firebaseConfig);

function LivePuja() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pujakereviews, setpujakereviews] = useState([]);
  const [pujaDetails, setPujaDetails] = useState(null);
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState("benefits");

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const fetchPujaDetails = async () => {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const pujasCollection = collection(db, "livepuja");
      const pujasSnapshot = await getDocs(pujasCollection);
      const pujasData = pujasSnapshot.docs
        .map((doc) => doc.data())
        .filter((puja) => puja.offeringId === id);      
      setPujaDetails(pujasData);
      
      const reviewGroupData = doc(db, "reviews", "livepuja");
      const reviewSpecificDocRef = doc(reviewGroupData, "reviews", id);
      const reviewGroupDocSnapshot = await getDoc(reviewGroupData);
      const reviewSpecificDocSnapshot = await getDoc(reviewSpecificDocRef);

      try {
        const reviewsData = [];
        if(reviewGroupDocSnapshot.exists() && reviewSpecificDocSnapshot.exists()){
          const docData = reviewSpecificDocSnapshot.data();
          reviewsData.push(...docData.review);
        }
        setpujakereviews(reviewsData);
      } catch (error) {
        console.log("Error getting documents:", error);
      }
    };

    fetchPujaDetails();
  }, [id]);

  if (!pujaDetails) {
    return <div>Loading...</div>;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const isLoggedIn = JSON.parse(localStorage.getItem("phoneNumber")) ? true : false;

  const analytics = getAnalytics(app);
  logEvent(analytics, "Visited: " + pujaDetails[0].name.en + " Page");

  return (
    <div className={styles.ayodhya}>
      <NavBar />
      <aside className={styles.listing}>
        <img src={pujaDetails[0].image} alt="Puja" className={styles.pujaImage} />
        <div className={styles.pujaParentDiv}>
          <header className={styles.header}>
            <div className={styles.iconTextWrapper}>
              <img src={templeIcon} alt="Temple Icon" className={styles.icon} />
              <h1 className={styles.title}>
                {language === "en"
                  ? pujaDetails[0].name?.en
                  : pujaDetails[0].name?.hi}
              </h1>
            </div>
            {pujaDetails[0].description?.en && (
              <p className={styles.subtitle}>
                {language === "en"
                  ? pujaDetails[0].description?.en
                  : pujaDetails[0].description?.hi}
              </p>
            )}
            <div className={styles.container}>
              <img src="https://d3vqmpzcil03dt.cloudfront.net/review_images-website/review_image_1.jpg" alt="Review 1" className={`${styles.image0} ${styles.image1}`} />
              <img
                src={`https://d3vqmpzcil03dt.cloudfront.net/review_images-website/review_image_${generateRandomNumbers()}.jpg`}
                alt="Review 2"
                className={`${styles.image0} ${styles.image2}`}
              />
              <img src="https://d3vqmpzcil03dt.cloudfront.net/review_images-website/review_image_20.jpg" alt="Review 3" className={`${styles.image0} ${styles.image3}`} />
              <p className={styles.text1}>
               <strong>Till now <span className={styles.colorText}>100+ Devotees</span> have booked pooja</strong>
              </p>
            </div>
            <strong className={styles.reviewsTitle}>Reviews</strong>
            <div className={styles.scrollContainer}>
              {pujakereviews.map((review, i) => (
                <div key={review.id} style={{ display: 'inline-block', width: '60%', margin: '0 0px' }}>
                  <PujaReview {...review} />
                </div>
              ))}
            </div>
          </header>
          <div className={styles.centralAlign}>
            <LivetimerDetails />
          <button
            className={styles.bookButton}
            onClick={() => {
              if (isLoggedIn) {
                navigate("/livepuja/" + id + "/payment", {
                  state: { id: id },
                });
              } else {
                navigate("/login/0/" + id, {
                  state: { id: id },
                });
              }
            }}
          >
            {t("Book Pooja at Rs. ") + " "}
            {pujaDetails[0].price.discounted_price > 0 ? (
              <del>{" ₹" + pujaDetails[0].price.en}</del>
            ) : (
              ""
            )}
            <strong>
              {pujaDetails[0].price.discounted_price
                ? " ₹" + pujaDetails[0].price.discounted_price
                : pujaDetails[0].price.en}
            </strong>
          </button>
          </div>
        </div>
      </aside>
      <main className={styles.mainContent}>
        <div className={styles.tabs}>
          <div className={styles.tabContainer}>
            <div className={`${activeTab === "benefits" ? styles.activeTab : styles.tab} ${styles.tabWidth}`} onClick={() => handleTabChange("benefits")}>
              {t("Benefits")}
            </div>
            {activeTab === "benefits" ? <div className={styles.line} /> : <div className={styles.line1} />}
          </div>
          <div className={styles.tabContainer}>
            <div
              className={`${activeTab === "itemsNeeded" ? styles.activeTab : styles.tab} ${styles.tabWidth}`}
              onClick={() => handleTabChange("itemsNeeded")}
            >
              {t("Items Needed")}
            </div>
            {activeTab === "itemsNeeded" ? <div className={styles.line} /> : <div className={styles.line1} />}
          </div>
          <div className={styles.tabContainer}>
            <div
              className={`${activeTab === "whatYouWillGet" ? styles.activeTab : styles.tab} ${styles.tabWidth}`}
              onClick={() => handleTabChange("whatYouWillGet")}
            >
              {t("What you will get")}
            </div>
            {activeTab === "whatYouWillGet" ? <div className={styles.line} /> : <div className={styles.line1} />}
          </div>
          <div className={styles.tabContainer}>
            <div
              className={`${activeTab === "poojaProcess" ? styles.activeTab : styles.tab} ${styles.tabWidth}`}
              onClick={() => handleTabChange("poojaProcess")}
            >
              {t("Pooja Process")}
            </div>
            {activeTab === "poojaProcess" ? <div className={styles.line} /> : <div className={styles.line1} />}
          </div>
        </div>
        <div className={styles.divider}></div>
        <section className={styles.features}>
          {activeTab === "benefits" && (
            <div>
              <ul>
                {pujaDetails[0].benefits?.map((benefit, index) => (
                  <li key={index}>
                    {language === "en" ? benefit?.en : benefit?.hi}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {activeTab === "itemsNeeded" && (
            <div>
              <ul>
                {pujaDetails[0].samagri?.map((samagri, index) => (
                  <li key={index}>
                    {language === "en" ? samagri?.en : samagri?.hi}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {activeTab === "whatYouWillGet" && (
            <div>
              <ul>
                {pujaDetails[0].outcomes?.map((outcome, index) => (
                  <li key={index}>
                    {language === "en" ? outcome?.en : outcome?.hi}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {activeTab === "poojaProcess" && (
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '0 0px' }}>
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} style={{ display: 'inline-block', width: 240, margin: '0 0px' }}>
                  <div
                    style={{
                      width: 220,
                      height: 80,
                      marginRight: 20,
                      borderRadius: 10,
                      backgroundColor: "#ED884266",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                      <div style={{ padding: "5px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ marginRight: 10 }}>
                            <div
                              style={{
                                width: 60,
                                height: 60,
                                borderRadius: "50%",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src={index === 0 ? Step1Icon : index === 1 ? Step2Icon : index === 2 ? Step3Icon : Step4Icon}
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                alt="Puja Process"
                              />
                            </div>
                          </div>
                          <div style={{ flex: 1, minWidth: 0 }}>
                            <h3 style={{ fontWeight: "bold", margin: "0" }}>Step {index + 1}</h3>
                            <p style={{ margin: "0", whiteSpace: 'pre-wrap', fontSize: '12px' }}>
                              {index === 0
                                ? "Enter your Name and Gotra"
                                : index === 1
                                ? "Finalize the payment after providing the necessary details"
                                : index === 2
                                ? "Experienced pandits conduct your pooja and receive video of pooja"
                                : "Get blessings and prasad delivered to your home"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>
      </main>
      <Footer />
    </div>
  );
}

function generateRandomNumbers() {
  return Math.floor(Math.random() * 19) + 2;
}

export default LivePuja;
