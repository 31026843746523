import React, { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../styles/screens/Ayodhya/Payment.module.css";

const SummaryCard = ({
  pujaDetails,
  pujaPrice,
  isCOD,
  couponDiscount,
  language,
}) => {
  const { t } = useTranslation();
  const[totalPrice, setToatlPrice] = useState(pujaPrice)
  const [pujaImage, setPujaImage] = useState("");


  useEffect(() => {
    const fetchLivePujas = () => {
      setToatlPrice(pujaPrice);
    };    
    fetchLivePujas();
  }, [pujaPrice]);

  useEffect (() => {
    if (Array.isArray(pujaDetails.image)) {
      setPujaImage(pujaDetails.image[0]);
    } else {
      setPujaImage(pujaDetails.image);
    }
  }, [pujaDetails])


  return (
    <div className={styles.summaryCard}>
      <h2 className={styles.summaryTitle}>{t("Summary")}</h2>
      <img src={pujaImage} alt="Puja" className={styles.pujaImage} />
      <h3 className={styles.pujaTitle}>
        {language === "en" ? pujaDetails.name?.en : pujaDetails.name?.hi}
      </h3>
      <p className={styles.original}>
        {t("Total Price")}: ₹ {totalPrice}
      </p>
      {isCOD && <p className={styles.cod}>{t("Delhivery Charges")}: ₹ 50</p>}
      {couponDiscount > 0 && (
        <p className={styles.discount}>{t("Coupon: ") + couponDiscount}</p>
      )}
      {pujaDetails.price.discounted_price &&
        pujaDetails.price.discounted_price < pujaDetails.price.en && (
          <>
            <p className={styles.discount}>
              {t("Discount")}: ₹{" "}
              {pujaDetails.price.en - pujaDetails.price.discounted_price}
            </p>
            <p className={styles.total}>
              {t("Total")}: ₹ {pujaPrice}
            </p>
          </>
        )}
    </div>
  );
};

export default SummaryCard;
