import React, { useState, useEffect } from "react";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import firebaseConfig from "../firebase";
import moment from 'moment'
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, getDoc, doc } from "firebase/firestore";
import "../styles/screens/AdminPanel.module.css";

const AdminPanel = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setAdmin] = useState(false);
  const navigate = useNavigate();
  const userPhoneNumber = JSON.parse(localStorage.getItem("phoneNumber"));
  const isLoggedIn = userPhoneNumber
    ? true
    : false;
  
    useEffect(() => {
      const fetchAdminData = async () => {
        try {
          const app = initializeApp(firebaseConfig);
          const db = getFirestore(app);
          const userDoc = doc(db, "adminpanelusers", userPhoneNumber);
          const userSnapshot = await getDoc(userDoc);
          console.log(userSnapshot.data());
          if(userSnapshot.exists()) {
            setAdmin(true);
          } else {
            setAdmin(false);
            navigate("/");
          }
        } catch (error) {
          console.error("Error fetching Admin data:", error);
        }
      };
    fetchAdminData();
    }, [userPhoneNumber, navigate]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const app = initializeApp(firebaseConfig);
          const db = getFirestore(app);
          const querySnapshot = await getDocs(collection(db, "orders"));
          const data = querySnapshot.docs.map((doc) => doc.data());
          if(isAdmin) {
            setOrders(data);
          }
          else { 
            setOrders([]);
          }
          setLoading(false);
          console.log(JSON.stringify(data, null, 2));
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
  
      if (!isLoggedIn) {
        navigate("/orderadminpanel/login");
      } else {
        fetchData();
      }
    }, [isAdmin, isLoggedIn, navigate]);

  return (
    <div className="horizontal-scroll">
      {(isLoggedIn && isAdmin) && (
        <>
          <NavBar />
          <ToastContainer />
          <div className="table-container">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>{t("S. No.")}</th>
                    <th>{t("Order ID")}</th>
                    <th>{t("Name")}</th>
                    <th>{t("Phone Number")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Payment Method")}</th>
                    <th>{t("Offering")}</th>
                    <th>{t("Price")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((orderObj, index) => {
                    const orderKey = Object.keys(orderObj)[0];
                    const order = orderObj[orderKey];

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>  
                        <td>{order?.orderId ?? ''}</td>
                        <td>{order?.name ?? ''}</td>
                        <td>{order?.phoneNumber ?? (order?.phone ?? '')}</td>
                        <td>
                          {order.updated_time && order.updated_time.seconds
                            ? moment.unix(order.updated_time.seconds).format('DD-MM-YYYY')
                            : ''}
                        </td>
                        <td>{order?.paymentMethod ?? ''}</td>
                        <td>{order?.pujaname ?? (order?.description ?? '')}</td>
                        <td>{order?.price ?? ''}</td>
                        <td>{order?.status ?? ''}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <Footer />
        </>
      )}
    </div>
  );
  
};

export default AdminPanel;
