import React from "react";
import NavBar from "../../components/Navbar";
import styles from "../../styles/screens/Privacypolicy.module.css";
import temple from "../../assets/ic_temple.png";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div>
      <NavBar />
      <div
        style={{
          backgroundColor: "#fff1ea",
        }}
      >
        <div className={styles.icon_text_wrapper}>
          <img src={temple} alt="Temple Icon" className={styles.icon}></img>
          <h1 className={styles.title}>{t("Privacy Policy")}</h1>
        </div>
        <div className={styles.privacy_policy_text}>
          <p>{t("privacyPolicy.introduction")}</p>
          <h2>{t("privacyPolicy.section1Title")}</h2>
          <p>{t("privacyPolicy.userInformation")}</p>
          <p>{t("privacyPolicy.deviceData")}</p>
          <ul>
            <li>{t("privacyPolicy.deviceAttributes")}</li>
            <li>{t("privacyPolicy.networkIdentifier")}</li>
            <li>{t("privacyPolicy.media")}</li>
            <li>{t("privacyPolicy.eventsInformation")}</li>
          </ul>
          <p>{t("privacyPolicy.logDataAndCookies")}</p>
          <p>{t("privacyPolicy.locationInformation")}</p>
          <p>{t("privacyPolicy.customerSupportInformation")}</p>
          <h2>{t("privacyPolicy.dataUsageTitle")}</h2>
          <p>{t("privacyPolicy.dataUsageContent")}</p>
          <h2>{t("privacyPolicy.disclosureTitle")}</h2>
          <p>{t("privacyPolicy.disclosureContent")}</p>
          <h2>{t("privacyPolicy.securityPracticesTitle")}</h2>
          <p>{t("privacyPolicy.securityPracticesContent")}</p>
          <h2>{t("privacyPolicy.storageTitle")}</h2>
          <p>{t("privacyPolicy.storageContent")}</p>
          <h2>{t("privacyPolicy.dataRightsTitle")}</h2>
          <p>{t("privacyPolicy.dataRightsContent")}</p>
          <h2>{t("privacyPolicy.retentionTitle")}</h2>
          <p>{t("privacyPolicy.retentionContent")}</p>
          <h2>{t("privacyPolicy.cookiesTitle")}</h2>
          <p>{t("privacyPolicy.cookiesContent")}</p>
          <h2>{t("privacyPolicy.communicationTitle")}</h2>
          <p>{t("privacyPolicy.communicationContent")}</p>
          <h2>{t("privacyPolicy.changesTitle")}</h2>
          <p>{t("privacyPolicy.changesContent")}</p>
          <h2>{t("privacyPolicy.contactTitle")}</h2>
          <p>{t("privacyPolicy.contactContent")}</p>
          <h2>{t("privacyPolicy.conclusionTitle")}</h2>
          <p>{t("privacyPolicy.conclusionContent")}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
