import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles/screens/Profile/Profile.module.css";

import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  setDoc,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

function AddAddressDialog({ isOpen, onClose }) {
  const { t } = useTranslation();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const phoneNumber = JSON.parse(localStorage.getItem("phoneNumber"));
  const [name, setName] = useState("");
  const [house, setHouse] = useState("");
  const [locality, setLocality] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");

  if (!isOpen) return null;

  const handleInputChange = (e, field) => {
    if (field === "name") setName(e.target.value);
    if (field === "house") setHouse(e.target.value);
    if (field === "locality") setLocality(e.target.value);
    if (field === "city") setCity(e.target.value);
    if (field === "pincode") {
      const input = e.target.value;
      const numbersOnly = input.replace(/\D/g, "");
      setPincode(numbersOnly);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const saved_address = {
      addressName: name,
      house: house,
      locality: locality,
      city: city,
      pincode: pincode,
    };

    localStorage.setItem("saved_address", JSON.stringify(saved_address));

    const userRef = doc(db, "saved_address", phoneNumber);
    const docSnapshot = await getDoc(userRef);
    if (docSnapshot.exists()) {
      await updateDoc(userRef, {
        ...docSnapshot.data(),
        [name]: { ...saved_address, updatedTime: serverTimestamp() },
      });
    } else {
      await setDoc(userRef, {
        [name]: { ...saved_address, createdTime: serverTimestamp() },
      });
    }
    onClose();
  };

  return (
    <form onSubmit={handleSubmit} className={styles.profileForm}>
      <input
        type="text"
        id="name"
        value={name}
        onChange={(e) => handleInputChange(e, "name")}
        required
        placeholder={t("Address name")}
      />
      <input
        type="text"
        id="house"
        value={house}
        required
        onChange={(e) => handleInputChange(e, "house")}
        placeholder={t("House Name/Number")}
      />
      <input
        type="text"
        id="locality"
        required
        value={locality}
        onChange={(e) => handleInputChange(e, "locality")}
        placeholder={t("Locality/Street")}
      />
      <input
        type="text"
        id="city"
        required
        value={city}
        onChange={(e) => handleInputChange(e, "city")}
        placeholder={t("City/Town")}
      />
      <input
        type="tel"
        id="pincode"
        name="pincode"
        required
        value={pincode}
        onChange={(e) => {
          handleInputChange(e, "pincode");
        }}
        placeholder={t("Pin Code")}
      />
      <button type="submit" className={styles.submitButton}>
        {t("Save Changes")}
      </button>
    </form>
  );
}
export default AddAddressDialog;
