import  { useEffect } from 'react';

function Metadata({
  title,
  description,
  keywords,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  canonicalUrl,
  noindex = false,
  dynamicMetadata = null,
  twitter,
  youtube,
  instagram,
  author,
  publisher,
  datePublished,
}) {
  useEffect(() => {
    const updateMetadata = (metadata) => {
      document.title = metadata.title || '';

      updateMetaTag('description', metadata.description);
      updateMetaTag('keywords', metadata.keywords);

      // Open Graph
      updateMetaTag('og:title', metadata.ogTitle || metadata.title);
      updateMetaTag('og:description', metadata.ogDescription || metadata.description);
      updateMetaTag('og:image', metadata.ogImage);
      updateMetaTag('og:url', metadata.ogUrl);
      updateMetaTag('og:type', 'website');
      updateMetaTag('og:locale', 'en_US');

      // Twitter
      updateMetaTag('twitter', metadata.twitter);

      // YouTube
      updateMetaTag('youtube', metadata.youtube);

      // Instagram
      updateMetaTag('instagram', metadata.instagram);

      // Author
      updateMetaTag('author', metadata.author);

      // Publisher
      updateMetaTag('publisher', metadata.publisher);

      // Date Published
      updateMetaTag('datePublished', metadata.datePublished);

      // Canonical URL
      updateLinkTag('canonical', metadata.canonicalUrl);

      // Robots
      updateMetaTag('robots', metadata.noindex ? 'noindex, nofollow' : 'index, follow');

      // Schema.org
      updateSchemaOrgMetadata(metadata);
    };

    const updateSchemaOrgMetadata = (metadata) => {
      const schemaOrgMetadata = {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: metadata.title,
        description: metadata.description,
        image: metadata.ogImage,
        url: metadata.ogUrl,
        author: metadata.author,
        publisher: metadata.publisher,
        datePublished: metadata.datePublished,
      };

      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify(schemaOrgMetadata);
      document.head.appendChild(script);
    };

    const updateMetaTag = (name, content) => {
      if (content === undefined || content === null) return;

      let meta = document.querySelector(`meta[name="${name}"]`) || document.querySelector(`meta[property="${name}"]`);

      if (!meta) {
        meta = document.createElement('meta');
        meta.setAttribute(name.startsWith('og:') ? 'property' : 'name', name);
        document.head.appendChild(meta);
      }

      meta.setAttribute('content', content);
    };

    const updateLinkTag = (rel, href) => {
      if (href === undefined || href === null) return;

      let link = document.querySelector(`link[rel="${rel}"]`);

      if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', rel);
        document.head.appendChild(link);
      }

      link.setAttribute('href', href);
    };

    // Initial update with static metadata
    updateMetadata({
      title,
      description,
      keywords,
      ogTitle,
      ogDescription,
      ogImage,
      ogUrl,
      canonicalUrl,
      noindex,
      twitter,
      youtube,
      instagram,
      author,
      publisher,
      datePublished,
    });

    // If dynamicMetadata is provided, fetch and update
    if (dynamicMetadata) {
      dynamicMetadata().then((updatedMetadata) => {
        updateMetadata({ ...updatedMetadata, noindex });
      }).catch((error) => {
        console.error('Error fetching dynamic metadata:', error);
      });
    }

    // Cleanup function
    return () => {
      document.title = 'Default Title';
      removeMetaTag('description');
      removeMetaTag('keywords');
      removeMetaTag('og:title');
      removeMetaTag('og:description');
      removeMetaTag('og:image');
      removeMetaTag('og:url');
      removeMetaTag('twitter');
      removeMetaTag('youtube');
      removeMetaTag('instagram');
      removeMetaTag('author');
      removeMetaTag('publisher');
      removeMetaTag('datePublished');
      removeLinkTag('canonical');
      removeMetaTag('robots');
    };
  }, [
    title,
    description,
    keywords,
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl,
    canonicalUrl,
    noindex,
    dynamicMetadata,
    twitter,
    youtube,
    instagram,
    author,
    publisher,
    datePublished,
  ]);

  return null;
}

const removeMetaTag = (name) => {
  const meta = document.querySelector(`meta[name="${name}"]`) || document.querySelector(`meta[property="${name}"]`);
  if (meta) {
    meta.remove();
  }
};

const removeLinkTag = (rel) => {
  const link = document.querySelector(`link[rel="${rel}"]`);
  if (link) {
    link.remove();
  }
};

export default Metadata;