import React, { useState, useEffect } from "react";
import NavBar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import styles from "../../styles/screens/Ayodhya/Payment.module.css";
import templeIcon from "../../assets/ic_temple.png";
import Footer from "../../components/Footer";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import firebaseConfig from "../../firebase";
import SummaryCard from "../../components/Payment/SummaryCard";
import getOrderId from "../../utils/OrderId";
import UpdateOrderFB from "../../components/Payment/UpdateOrderFB";
import openRazorpay from "../../components/Payment/Razorpay";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function LivepujaPayment() {
  const { id } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [pujaDetails, setPujaDetails] = useState(null);
  const [name, setName] = useState([
    JSON.parse(localStorage.getItem("name"))
      ? JSON.parse(localStorage.getItem("name"))
      : "",
  ]);
  const phone = JSON.parse(localStorage.getItem("phoneNumber"));
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("email"))
      ? JSON.parse(localStorage.getItem("email"))
      : ""
  );
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [date] = useState("");
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const [pujaPrice, setPujaPrice] = useState(0);
  const userType = localStorage.getItem("userType");
  const [prasadOption, setPrasadOption] = useState("withoutPrasad");
  const [prasadPrice, setPrasadPrice] = useState(0);

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const fetchPujaDetails = async () => {
      const pujaDocRef = collection(db, "livepuja");
      const pujaQuerySnapshot = await getDocs(pujaDocRef);

      try {
        pujaQuerySnapshot.forEach(async (pujaDocument) => {
          const offeringData = pujaDocument.data();
          if (offeringData.offeringId === id) {
            setPujaDetails(offeringData);
            setPujaPrice( offeringData.price.en);
            setPrasadPrice(parseInt(offeringData.price.prasad));
          }
        });
      } catch (error) {
        console.error("Error getting documents:", error);
      }
    };

    fetchPujaDetails();
  }, [id, userType]);

  useEffect(() => {
    if (pujaDetails) {
      if (paymentMethod === "online") {
        setPujaPrice(
          parseInt(pujaDetails.price.en)
        );
      }
    }
  }, [paymentMethod, pujaDetails]);

  if (!pujaDetails) {
    return <div>Loading...</div>;
  }

  const onClickBook = async (e) => {
    console.log(submitting);
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);
    setPujaPrice(pujaPrice);
    console.log("PUJA PRICE SET:", pujaPrice);
    const orderId = await getOrderId(pujaPrice);
    console.log("ORDER ID: ", orderId);
    if (!orderId) {
      console.log("No order id");
    }

    try {
      const skuName = ((paymentMethod === "online" ? (pujaDetails && pujaDetails.sku && pujaDetails.sku.prepaid && pujaDetails.sku.prepaid.name) : (pujaDetails && pujaDetails.sku && pujaDetails.sku.cod && pujaDetails.sku.cod.name)) || '');
      const skuCode = ((paymentMethod === "online" ? (pujaDetails && pujaDetails.sku && pujaDetails.sku.prepaid && pujaDetails.sku.prepaid.code) : (pujaDetails && pujaDetails.sku && pujaDetails.sku.cod && pujaDetails.sku.cod.code)) || '');
      setPaymentMethod("online");
      const orderRef = doc(db, "orders", orderId);
      await setDoc(orderRef, {
        name: name[0],
        name2: name[1] ? name[1] : "",
        name3: name[2] ? name[2] : "",
        name4: name[3] ? name[3] : "",
        phone,
        email,
        platform: "web",
        pujaname: pujaDetails.name?.en,
        pujadescription: pujaDetails.description.en,
        pujadata: date,
        price: pujaPrice,
        skuname: skuName,
        skucode: skuCode,
        orderId: orderId,
        offeringId: id,
        image: pujaDetails.image,
        quantity: '1',
        status: "PAYMENT_INITIATED",
        created_time: serverTimestamp(),
        updated_time: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    console.log("PAYMENT METHOD: ", paymentMethod);
    UpdateOrderFB("0", orderId, paymentMethod, phone, id);

    if (paymentMethod === "online") {
      console.log(name);
      openRazorpay(
        0,
        orderId,
        pujaPrice,
        id,
        phone,
        pujaDetails.name?.en,
        email,
        name
      );
    }
  };

  const handlePrasadOptionChange = (event) => {
    const selectedOption = event.target.value;
  
    if (selectedOption === "withoutPrasad") {
      setPrasadOption("withoutPrasad");
      setPujaPrice(pujaPrice - prasadPrice);
    } else if (selectedOption === "withPrasad") {
      setPrasadOption("withPrasad");
      setPujaPrice(pujaPrice + prasadPrice);
    }
  };  

  return (
    <div className={styles.ayodhya}>
      <NavBar />
      <main className={styles.mainContent}>
        <section className={styles.features}>
          <header className={styles.header}>
            <div className={styles.iconTextWrapper}>
              <img src={templeIcon} alt="Temple bg" className={styles.icon} />
              <h1 className={styles.title}>
                {language === "en"
                  ? pujaDetails.name?.en
                  : pujaDetails.name?.hi}
              </h1>
            </div>
            {pujaDetails.description?.en && (
              <p className={styles.subtitle}>
                {language === "en"
                  ? pujaDetails.description?.en
                  : pujaDetails.description?.hi}
              </p>
            )}
          </header>
          <h2>{t("Please provide details for puja")}</h2>
          <div className={styles.formGroup}>
            <label htmlFor="Name">{t("Name") + " "}</label>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onClickBook(e);
            }}
          >
            {name.map((nameValue, index) => (
              <div className={styles.formGroup} key={index}>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id={`name${index + 1}`}
                    name={`name${index + 1}`}
                    value={nameValue}
                    onChange={(e) => {
                      const updatedNames = [...name];
                      updatedNames[index] = e.target.value;
                      if (!submitting) {
                        setName(updatedNames);
                      }
                    }}
                    placeholder={
                      t("Name") +
                      (index === 0 ? t(" (required)") : t(" (optional)"))
                    }
                    required={index === 0}
                  />
                  {index === 0 && name.length < 4 && (
                    <button
                      type="button"
                      className={`${styles.addButton} ${styles.plusSign}`}
                      onClick={() => {
                        if (!submitting) {
                          setName([...name, ""]);
                          setPujaPrice(pujaPrice + 150);
                        }
                      }}
                    >
                      +
                    </button>
                  )}
                  {index > 0 && (
                    <button
                      type="button"
                      className={`${styles.addButton} ${styles.minusSign}`}
                      onClick={() => {
                        if (!submitting) {
                          const updatedNames = [...name];
                          updatedNames.splice(index, 1);
                          setName(updatedNames);
                          setPujaPrice(pujaPrice - 150);
                        }
                      }}
                    >
                      -
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className={styles.formGroup}>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={phone}
                placeholder={t("Phone Number") + t(" (required)")}
                readOnly
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => {
                  if (!submitting) setEmail(e.target.value);
                }}
                placeholder={t("Email") + t(" (optional)")}
              />
            </div>
            <div className="radioGroup">
              <div className="radioLabel">
                <input
                  type="radio"
                  id="withoutPrasad"
                  name="prasadOption"
                  value="withoutPrasad"
                  checked={prasadOption === "withoutPrasad"}
                  onChange={handlePrasadOptionChange}
                />
              <label htmlFor="withoutPrasad">Without Prasad</label>
            </div>
            <div className="radioLabel">
              <input
              type="radio"
              id="withPrasad"
              name="prasadOption"
              value="withPrasad"
              checked={prasadOption === "withPrasad"}
              onChange={handlePrasadOptionChange}
            />
            <label htmlFor="withPrasad">With Prasad</label>
            </div>
          </div>
            <div className={styles.formGroup}>
              <label htmlFor="date">{t("Date of Pooja")}</label>
            </div>
            <div className={styles.formGroup}>
              <span className={styles.dateBox}>{pujaDetails.date}</span>
            </div>
              <button className={styles.bookButton}>
                {!submitting ? t("Make Payment") : t("Loading...")}
              </button>
          </form>
        </section>
        <aside className={styles.listing}>
          <SummaryCard
            pujaDetails={pujaDetails}
            pujaPrice={pujaPrice}
            language={language}
          />
        </aside>
      </main>
      <Footer />
    </div>
  );
}

export default LivepujaPayment;
