import React, { useState } from "react";
import NavBar from "../../components/Navbar";
import styles from "../../styles/screens/Profile/Login.module.css";
import Footer from "../../components/Footer";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import starIcon from "../../assets/login/login.png";
import loginIcon from "../../assets/login/hand.png";
import moveIcon from "../../assets/login/move.png";
import generateReferralCode from "../../utils/Referral";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import firebaseConfig from "../../firebase";

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();
auth.useDeviceLanguage();

const MarchantLogin = () => {
  const { parentReferralId } = useParams() || "";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [shopName, setShopName] = useState("");
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [inviteCode, setInviteCode] = useState(parentReferralId || "");

  const analytics = getAnalytics(app);
  logEvent(analytics, "Visited: Merchant Login Page");

  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("Recaptcha resolved.");
          },
        }
      );
      window.recaptchaVerifier.render().catch((error) => {
        console.error("Recaptcha render error: ", error);
      });
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!phoneNumber || phoneNumber.length < 10) {
      toast.error(t("Please full name and correct mobile number"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setUpRecaptcha();
    const phoneNumberWithCountryCode = phoneNumber;
    const appVerifier = window.recaptchaVerifier;

    toast.info(t("OTP Sent"), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumberWithCountryCode,
        appVerifier
      );
      setVerificationId(confirmationResult.verificationId);
    } catch (error) {
      console.error("Error sending OTP: ", error.message);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!verificationId) {
      toast.error(t("Failed to login"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);

      const userRef = doc(db, "merchants", phoneNumber);
      const userSnapshot = await getDoc(userRef);

      // TOOD check the invite code of merchant login

      if (userSnapshot.exists()) {
        await updateDoc(userRef, {
          last_login: serverTimestamp(),
        });

        toast.success(t("Sign in Successful"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        await setDoc(userRef, {
          phoneNumber: phoneNumber,
          userName: shopName,
          invitedBy: inviteCode,
          platform: "web",
          userType: "merchant",
          referral_code: "M" + generateReferralCode(phoneNumber),
          time: serverTimestamp(),
          last_login: serverTimestamp(),
        });

        const referrRef = doc(
          db,
          "merchant_referral_db",
          "M" + generateReferralCode(phoneNumber)
        );
        const referrSnapshot = await getDoc(referrRef);

        if (!referrSnapshot.exists()) {
          await setDoc(referrRef, {
            createdAt: serverTimestamp(),
          });
        }

        const referredRef = doc(db, "merchant_referral_db", inviteCode);
        const referredSnapshot = await getDoc(referredRef);

        if (referredSnapshot.exists()) {
          const referredUserData = referredSnapshot.data().referred_users || [];
          await updateDoc(referredRef, {
            web_signup_count:
              (referredSnapshot.data().web_signup_count || 0) + 1,
            referred_users: [
              ...referredUserData,
              {
                phonenumber: phoneNumber,
                platform: "web",
                userType: "merchant",
                timestamp: new Date(),
              },
            ],
          });
        } else {
          await setDoc(referredRef, {
            web_signup_count: 1,
            referred_users: [
              {
                phonenumber: phoneNumber,
                platform: "web",
                userType: "merchant",
                timestamp: new Date(),
              },
            ],
          });
        }
      }

      localStorage.setItem("phoneNumber", JSON.stringify(phoneNumber));
      localStorage.setItem(
        "referral_code",
        "M" + generateReferralCode(phoneNumber)
      );
      localStorage.setItem("userType", "merchant");
      localStorage.setItem("invitedBy", inviteCode);

      navigate("/profile");
    } catch (error) {
      console.error("Error verifying OTP: ", error.message);
    }
  };

  return (
    <div className={styles.ayodhya}>
      <NavBar />
      <ToastContainer />
      <div className={styles.mainArea}>
        <main className={styles.mainContent}>
          <section className={styles.section}>
            <img src={loginIcon} alt="Puja" className={styles.loginImage} />
            <h1 className={styles.title}>{t("Online Blessing")}</h1>
            <h3>
              {t(
                "Receive blessings through pooja  online at your religious temple."
              )}
            </h3>
            <img src={moveIcon} alt="Puja" className={styles.loginImage} />
          </section>
          <aside className={styles.listing}>
            <img src={starIcon} alt="Puja" className={styles.loginImage} />
            {!verificationId && (
              <form onSubmit={handleSendOtp} className={styles.authForm}>
                <h2>{t("Get started")}</h2>
                <input
                  placeholder={t("Please enter your Shop Name")}
                  type="text"
                  id="name"
                  name="name"
                  value={shopName}
                  className={styles.authInput}
                  onChange={(e) => setShopName(e.target.value)}
                  required
                />
                <input
                  placeholder={t("Please enter invite code")}
                  type="text"
                  id="code"
                  name="code"
                  value={inviteCode}
                  className={styles.authInput}
                  onChange={(e) => setInviteCode(e.target.value)}
                  required
                />
                <PhoneInput
                  placeholder={t("Please enter your phone number")}
                  defaultCountry="IN"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  required
                  className={styles.phoneInput}
                />
                <button type="submit" className={styles.submitButton}>
                  {t("Send OTP")}
                </button>
              </form>
            )}
            {verificationId && (
              <form className={styles.authForm}>
                <h2>{t("Welcome to Dharmik")}</h2>
                <label htmlFor="phoneNumber">{t("Enter OTP")}</label>
                <input
                  type="tel"
                  id="otp"
                  name="otp"
                  value={otp}
                  className={styles.authInput}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                <button
                  onClick={handleVerifyOtp}
                  className={styles.submitButton}
                >
                  {t("Verify OTP")}
                </button>
              </form>
            )}
            <div id="recaptcha-container"></div>
          </aside>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default MarchantLogin;
