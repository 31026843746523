import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../components/Navbar";
import styles from "../styles/screens/Result.module.css";
import Footer from "../components/Footer";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import instaIcon from "../assets/sm_icon/insta.png";
import fbIcon from "../assets/sm_icon/fb.png";
import twitterIcon from "../assets/sm_icon/twitter.png";
import whatsappIcon from "../assets/sm_icon/whatsapp.png";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const key_id = "rzp_live_vErndEFnkEHQsN";

const Result = () => {
  const { group, id, orderId } = useParams();
  const [status, setStatus] = useState("");
  const [language, setLanguage] = useState("en");
  const [pujaDetails, setPujaDetails] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const phone = JSON.parse(localStorage.getItem("phoneNumber"));
  const referralId = localStorage.getItem("referral_code");
  const userType = localStorage.getItem("userType");
  const invitedBy = localStorage.getItem("invitedBy");
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    let pujaDocRef;
    const fetchPujaDetails = async () => {
      if(group === "0") {
        pujaDocRef = doc(db, "livepuja", id);
      } else {
        pujaDocRef = doc(db, "poojaplaces", group, "offerings", id);
      }
      const pujaDocSnap = await getDoc(pujaDocRef);

      if (pujaDocSnap.exists()) {
        setPujaDetails(pujaDocSnap.data());
      } else {
        console.log("No such document!");
      }

      fetch("https://checkorder-ptmfa2eama-uc.a.run.app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ orderId: orderId }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          setData(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    fetchPujaDetails();
  }, [group, id, orderId]);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const orderRef = doc(db, "orders", orderId);
        const orderSnapshot = await getDoc(orderRef);
        const orderData = orderSnapshot.data();

        if (orderData) {
          setPrice(orderData.price);

          let newStatus;

          if (data.status === "paid") {
            newStatus = "PAYMENT_SUCCESS";
          } else if (orderData.paymentMethod === "cod") {
            newStatus = "BOOKED";
          } else if (
            orderData.paymentMethod === "online" &&
            (data.status === "failed")
          ) {
            newStatus = "PAYMENT_FAILED";
          } else if(orderData.paymentMethod === "online" && data.status === "created") {
            newStatus = "PAYMENT_PENDING";
          }else if(orderData.paymentMethod === "online" && data.status === "attempted") {
            newStatus = "PAYMENT_UNSUCESSFUL";
          } 

          setStatus(newStatus);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (data) fetchStatus();
  }, [data, orderId, phone, userType, invitedBy]);

  useEffect(() => {
    async function checkReferralCode(orderData, newStatus) {
      const orderHistoryRef = doc(db, "order_history", phone);
      const orderHistorySnapshot = await getDoc(orderHistoryRef);
      if (orderHistorySnapshot.exists()) {
        const orderHistoryData = orderHistorySnapshot.data();
        for (const order of Object.values(orderHistoryData)) {
          if (order.status === "PAYMENT_SUCCESS" || order.status === "BOOKED") {
            console.log("Not a first order");
            return;
          }
        }
      }

      var reff;
      if (invitedBy.length > 6) reff = "merchant_referral_db";
      else reff = "referral_db";

      const referredRef = doc(db, reff, invitedBy);
      const referredSnapshot = await getDoc(referredRef);

      if (referredSnapshot.exists()) {
        const referredBookingData =
          referredSnapshot.data().referred_booking || [];
        const prevBonus = referredSnapshot.data().merchant_bonus_amount || 0;
        const hasOrder = referredBookingData.some(
          (booking) => booking.orderid === orderId
        );

        if (!hasOrder && invitedBy.length > 6) {
          await updateDoc(referredRef, {
            merchant_bonus_amount:
              prevBonus + Math.round(parseFloat(orderData.price) / 10),
            referred_booking: [
              ...referredBookingData,
              {
                booking_type: orderData.paymentMethod,
                merchant_bonus_amount: Math.round(
                  parseFloat(orderData.price) / 10
                ),
                order_amount: orderData.price,
                orderid: orderId,
                platform: "web",
                phonenumber: phone,
                timestamp: new Date(),
              },
            ],
          });
        } else if (!hasOrder && invitedBy.length === 6) {
          await updateDoc(referredRef, {
            bonus_amount:
              prevBonus + Math.round(parseFloat(orderData.price) / 10),
            referred_booking: [
              ...referredBookingData,
              {
                booking_type: orderData.paymentMethod,
                bonus_amount: Math.round(parseFloat(orderData.price) / 10),
                order_amount: orderData.price,
                orderid: orderId,
                platform: "web",
                phonenumber: phone,
                timestamp: new Date(),
              },
            ],
          });
        }
      } else {
        if (invitedBy.length > 6) {
          await setDoc(referredRef, {
            merchant_bonus_amount: Math.round(parseFloat(orderData.price) / 10),
            referred_booking: [
              {
                booking_type: orderData.paymentMethod,
                merchant_bonus_amount: Math.round(
                  parseFloat(orderData.price) / 10
                ),
                order_amount: orderData.price,
                orderid: orderId,
                platform: "web",
                phonenumber: phone,
                timestamp: new Date(),
              },
            ],
          });
        } else {
          await setDoc(referredRef, {
            bonus_amount: Math.round(parseFloat(orderData.price) / 10),
            referred_booking: [
              {
                booking_type: orderData.paymentMethod,
                bonus_amount: Math.round(parseFloat(orderData.price) / 10),
                order_amount: orderData.price,
                orderid: orderId,
                platform: "web",
                phonenumber: phone,
                timestamp: new Date(),
              },
            ],
          });
        }
      }
    }

    const fetchStatus = async () => {
      try {
        const orderRef = doc(db, "orders", orderId);
        const orderSnapshot = await getDoc(orderRef);
        const orderData = orderSnapshot.data();

        if (orderData) {
          setPrice(orderData.price);

          let newStatus;

          if (data.status === "paid") {
            newStatus = "PAYMENT_SUCCESS";
          } else if (orderData.paymentMethod === "cod") {
            newStatus = "BOOKED";
          } else if (
            orderData.paymentMethod === "online" &&
            (data.status === "created" || data.status === "create")
          ) {
            newStatus = "PAYMENT_FAILED";
          }

          setStatus(newStatus);

          if (newStatus === "PAYMENT_SUCCESS") {
            const orderCodRef = doc(db, "success_order", orderId);
            await setDoc(orderCodRef, {
              ...orderData,
              status: "PAYMENT_SUCCESS",
            });
          } else if (newStatus === "BOOKED") {
            const orderCodRef = doc(db, "cod_order", orderId);
            await setDoc(orderCodRef, {
              ...orderData,
              status: "BOOKED",
            });
          } else if (newStatus === "PAYMENT_FAILED") {
            const orderCodRef = doc(db, "failed_order", orderId);
            await setDoc(orderCodRef, {
              ...orderData,
              status: "PAYMENT_FAILED",
            });
          }

          if (newStatus === "PAYMENT_SUCCESS" || newStatus === "BOOKED") {
            if (invitedBy.length > 2) {
              await checkReferralCode(orderData, newStatus);
            }
          }

          const orderPendingRef = doc(db, "order_history", phone);
          const orderHistorySnapshot = await getDoc(orderPendingRef);

          if (orderHistorySnapshot.exists()) {
            const updatedOrderData = {
              ...orderData,
              status: newStatus,
              hindi_status: t(
                newStatus === "PAYMENT_SUCCESS"
                  ? "भुगतान सफल"
                  : newStatus === "BOOKED"
                  ? "बुक किया गया"
                  : "भुगतान विफल"
              ),
              updated_time: serverTimestamp(),
            };
            await updateDoc(orderPendingRef, {
              [orderId]: updatedOrderData,
            });
          }

          await updateDoc(orderRef, {
            status: newStatus,
            updated_time: serverTimestamp(),
          });
        }
      } catch (e) {
        console.error("Error fetching status: ", e);
      }
    };

    if (data) {
      fetchStatus();
    }
  }, [data, invitedBy, orderId, phone, t, userType]);

  if (!pujaDetails) {
    return <div>Loading...</div>;
  } else {
    console.log(price);
  }

  const copyReferralCode = (str) => {
    const hitext =
      "अपने दरवाजे पर दिव्य आशीर्वाद प्राप्त करें! 🕉️ मेरे रेफरल कोड *" +
      referralId +
      "* का उपयोग करें और Dharmik ऐप डाउनलोड करें, भारत भर के मंदिरों से पूजा बुक करें और प्रसाद प्राप्त करें। पहले बुकिंग पर 10% का छूट प्राप्त करें। 🌟 घर पर दिव्य आशीर्वाद का अनुभव करें! 🙏 \nऐप लिंक: https://dharmikapp.co/share-playstore-web";
    const engText =
      "Unlock divine blessings at your doorstep! 🕉️ Use my referral code *" +
      referralId +
      "* to download the Dharmik app, book personalized pujas, and receive blessed prasad from temples across India. Enjoy a 10% discount on your first booking. 🌟 Experience spirituality like never before! 🙏 \nApp link: https://dharmikapp.co/share-playstore-web";

    const url = language === "en" ? engText : hitext;
    const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    const instaShareUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
      url
    )}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url
    )}`;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      url
    )}`;

    if (str === "fb") {
      window.open(fbShareUrl, "_blank");
    } else if (str === "insta") {
      window.open(instaShareUrl, "_blank");
    } else if (str === "twitter") {
      window.open(twitterShareUrl, "_blank");
    } else if (str === "wa") {
      window.open(whatsappShareUrl, "_blank");
    }
  };

  const renderButton = () => {
    if (status === "ORDER_IN_TRANSIT") {
      return null;
    } else if (status === "PAYMENT_SUCCESS") {
      return (
        <button className={styles.button} onClick={() => goToHomepage()}>
          {t("Go to Homepage")}
        </button>
      );
    } else if (status === "PAYMENT_FAILED") {
      return (
        <button className={styles.button} onClick={() => retryPayment()}>
          {t("Retry")}
        </button>
      );
    } else if (status === "PAYMENT_PENDING" || status === "INITIATED") {
      return (
        <button className={styles.button} onClick={() => retryPayment()}>
          {t("Complete Payment")}
        </button>
      );
    }
  };

  const goToHomepage = () => {
    navigate("/");
  };

  // Missed here, make the standared checkout here
  const retryPayment = async () => {
    const payload = {
      key_id: key_id,
      order_id: orderId,
      name: "Dharmik App",
      description: pujaDetails.name?.en,
      image: logo,
      prefill: {
        contact: phone,
      },
      notes:
        "Price = " +
        (pujaDetails.price.discounted_price < pujaDetails.price.en
          ? pujaDetails.price.discounted_price
          : pujaDetails.price.en),
      callback_url:
        "https://dharmikapp.in/payment/result/" + group + "/" + id + "/" + orderId,
      // cancel_url: "https://dharmikapp.in/payment/result/" + group + "/" + id + "/" + orderId,

      handler: function (response) {
      // Handle successful payment here
      window.location.href =
        "https://dharmikapp.in/payment/result/" + group + "/" + id + "/" + orderId;
    },
    modal: {
      ondismiss: function () {
        // Handle the case where the user cancels the payment
        window.location.href =
          "https://dharmikapp.in/payment/result/" + group + "/" + id + "/" + orderId;
      }
    }
    };

    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://api.razorpay.com/v1/checkout/embedded";

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;

        if (key === "prefill") {
          for (const subKey in payload[key]) {
            if (payload[key].hasOwnProperty(subKey)) {
              const subInput = document.createElement("input");
              subInput.type = "hidden";
              subInput.name = "prefill[" + subKey + "]";
              subInput.value = payload[key][subKey];
              form.appendChild(subInput);
            }
          }
        } else {
          input.value = payload[key];
          form.appendChild(input);
        }
      }
    }

    try {
      const orderRef = doc(db, "orders", orderId);
      const orderSnapshot = await getDoc(orderRef);

      if (orderSnapshot.exists()) {
        await updateDoc(orderRef, {
          status: "PAYMENT_PENDING",
          updated_time: serverTimestamp(),
        });
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    document.body.appendChild(form);

    form.submit();
  };

  return (
    <div>
      <NavBar />
      <div className={styles.result}>
        <div className={styles.cardContainer}>
          <h3 className={styles.title}>{t("Status : ") + t(status)}</h3>
          <h3 className={styles.pujaTitle}>{t("Order ID : ") + orderId}</h3>
          {renderButton()}
          <div className={styles.card}>
            <section className={styles.section}>
              <img
                src={pujaDetails.image}
                alt="Puja"
                className={styles.pujaImage}
              />
            </section>
            <aside className={styles.aside}>
              <div className={styles.iconTextWrapper}>
                <h3 className={styles.summarytitle}>
                  {language === "en"
                    ? pujaDetails.name?.en
                    : pujaDetails.name?.hi}
                </h3>
              </div>
              <section className={styles.features}>
                <h4>{t("Benefits")}</h4>
                {pujaDetails.benefits?.map((benefit, index) => (
                  <div className={styles.features_items}>
                    <span>{language === "en" ? benefit?.en : benefit?.hi}</span>
                  </div>
                ))}
                <h4>{t("What you will get")}</h4>
                {pujaDetails.outcomes?.map((outcome, index) => (
                  <div className={styles.features_items}>
                    <span>{language === "en" ? outcome?.en : outcome?.hi}</span>
                  </div>
                ))}
              </section>
            </aside>
          </div>
          <div className={styles.bottom_part}>
            <h2>{t("Share your Joy and Earn")}</h2>{" "}
            <h5>
              {t(
                "Refer your friend and earn 10% (upto Rs. 500) on the puja booked by them, Your friends also get 10% off."
              )}
            </h5>{" "}
            <h6>
              {t(
                "Use your unique code or effortlessly share it across your social networks to spread the word!"
              )}
            </h6>
            <div className={styles.referral_code_container}>
              <div className={styles.referral_code}>
                <input type="text" id="referralCode" value={referralId} />
                <span className={styles.spacing}></span>
                <span>{t("or")}</span>
                <span className={styles.spacing}></span>
                <img
                  src={fbIcon}
                  onClick={() => copyReferralCode("fb")}
                  alt={t("share")}
                />
                <span className={styles.spacing}></span>
                <img
                  src={instaIcon}
                  onClick={() => copyReferralCode("insta")}
                  alt={t("share")}
                />
                <span className={styles.spacing}></span>
                <img
                  src={twitterIcon}
                  onClick={() => copyReferralCode("twitter")}
                  alt={t("share")}
                />
                <span className={styles.spacing}></span>
                <img
                  src={whatsappIcon}
                  onClick={() => copyReferralCode("wa")}
                  alt={t("share")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Result;
